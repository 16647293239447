import React from "react";
import codingMagicCi from "../assets/images/codingMagic-ci.png";
import codingMagicCiMob from "../assets/images/codingMagic-ci-mob.png";
import lhsCi from "../assets/images/lhs-ci.png";
import lhsCiMob from "../assets/images/lhs-ci-mob.png";
import ozCi from "../assets/images/oz-ci_2th.png";
import ozCiMob from "../assets/images/oz-ci_2th.png";
import kfPartnersCi from "../assets/images/kf-partners-ci.png";
import kfPartnersCiMob from "../assets/images/kf-partners-ci-mob.png";
import sellfarmCi from "../assets/images/sellfarm-ci.png";
import watcherCi from "../assets/images/watcher-ci.png";
import watcherCiMob from "../assets/images/watcher-ci-mob.png";
import { BrandListWrap } from "../styles/common/brandList.styled";

const BrandList = ({ selectedTab }) => {
  const brandData = [
    {
      imgSrc: codingMagicCi,
      imgSrcMob: codingMagicCiMob,
      imgAlt: "코딩마법학교 로고",
      colors: [
        {
          backgroundColor: "#000000",
          name: "NXR Black",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 100", "RGB  R 0 · G 0 · B 0", "HEX  #000000"],
        },
        {
          backgroundColor: "#FFFFFF",
          name: "NXR White",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 0", "RGB  R 255 · G 255 · B 255", "HEX  #FFFFFF"],
        },
      ],
    },
    {
      imgSrc: lhsCi,
      imgSrcMob: lhsCiMob,
      imgAlt: "라이프해킹스쿨 로고",
      colors: [
        {
          backgroundColor: "#000000",
          name: "LHS Black",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 100", "RGB  R 0 · G 0 · B 0", "HEX  #000000"],
        },
        {
          backgroundColor: "#FB0000",
          name: "LHS Red",
          border: "#FB0000",
          codes: ["CMYK  C 0 · M 100 · Y 100 · K 2", "RGB  R 251 · G 0 · B 0", "HEX  #FB0000"],
        },
        {
          backgroundColor: "#FFFFFF",
          name: "LHS White",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 0", "RGB  R 255 · G 255 · B 255", "HEX  #FFFFFF"],
        },
      ],
    },
    {
      imgSrc: ozCi,
      imgSrcMob: ozCiMob,
      imgAlt: "오즈코딩스쿨 로고",
      colors: [
        {
          backgroundColor: "#000000",
          name: "OZ Black",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 100", "RGB  R 0 · G 0 · B 0", "HEX  #000000"],
        },
        {
          backgroundColor: "#6526F5",
          name: "OZ Purple",
          border: "#6526F5",
          codes: ["CMYK  C 59 · M 84 · Y 0 · K 4", "RGB  R 101 · G 38 · B 245", "HEX  #6526F5"],
        },
        {
          backgroundColor: "#FFFFFF",
          name: "OZ White",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 0", "RGB  R 255 · G 255 · B 255", "HEX  #FFFFFF"],
        },
      ],
    },
    {
      imgSrc: kfPartnersCi,
      imgSrcMob: kfPartnersCiMob,
      imgAlt: "한국프랜차이즈 파트너스 로고",
      colors: [
        {
          backgroundColor: "#000000",
          name: "KF Partners Black",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 100", "RGB  R 0 · G 0 · B 0", "HEX  #000000"],
        },
        {
          backgroundColor: "#FF5B00",
          name: "KF Partners Orange",
          border: "#FF5B00",
          codes: ["CMYK  C 0 · M 65 · Y 100 · K 0", "RGB  R 255 · G 91 · B 0", "HEX  #FF5B00"],
        },
        {
          backgroundColor: "#FFFFFF",
          name: "KF Partners White",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 0", "RGB  R 255 · G 255 · B 255", "HEX  #FFFFFF"],
        },
      ],
    },
    {
      imgSrc: sellfarmCi,
      imgSrcMob: sellfarmCi,
      imgAlt: "셀팜 로고",
      colors: [
        {
          backgroundColor: "#004836",
          name: "Sellfarm Green",
          border: "#004836",
          codes: ["CMYK  C 100 · M 0 · Y 25 · K 72", "RGB  R 0 · G 72 · B 54", "HEX  #004836"],
        },
        {
          backgroundColor: "#FAF1D9",
          name: "Sellfarm Yellow",
          border: "#FAF1D9",
          codes: ["CMYK  C 0 · M 4 · Y 13 · K 2", "RGB  R 250 · G 241 · B 217", "HEX  #FAF1D9"],
        },
      ],
    },
    {
      imgSrc: watcherCi,
      imgSrcMob: watcherCiMob,
      imgAlt: "왓쳐 로고",
      colors: [
        {
          backgroundColor: "#000000",
          name: "Watcher Black",
          border: "#000000",
          codes: ["CMYK  C 0 · M 0 · Y 0 · K 100", "RGB  R 0 · G 0 · B 0", "HEX  #000000"],
        },
        {
          backgroundColor: "#0049FF",
          name: "Watcher Blue",
          border: "#0049FF",
          codes: ["CMYK  C 100 · M 71 · Y 0 · K 0", "RGB  R 0 · G 73 · B 255", "HEX  #0049FF"],
        },
        {
          backgroundColor: "#7335EA",
          name: "Watcher Purple",
          border: "#7335EA",
          codes: ["CMYK  C 51 · M 77 · Y 0 · K 8", "RGB  R 115 · G 53 · B 234", "HEX  #7335EA"],
        },
      ],
    },
  ];

  return (
    <BrandListWrap className="brand-list">
      {brandData.map((brand, index) => (
        <li
          key={index}
          className={`brand-item ${selectedTab === index ? "active" : ""} ${
            brand.imgAlt === "셀팜 로고" ? "sellfarm-item" : ""
          }`}
        >
          <div className="brand-img-wrap">
            <img src={brand.imgSrc} className="brand-img-item pc_only" alt={brand.imgAlt} />
            <img src={brand.imgSrcMob} className="brand-img-item pc_none" alt={brand.imgAlt} />
          </div>
          <div className="keycolor-wrap">
            <div className="desc-title">
              <p className="desc-title-item">Key Color</p>
            </div>
            <div className="desc-wrap">
              {brand.colors.map((color, colorIndex) => (
                <div key={colorIndex} className="color-wrap">
                  <div
                    className="color-box"
                    style={{
                      backgroundColor: color.backgroundColor,
                      border: `1px solid ${color.border}`,
                    }}
                  ></div>
                  <div className="color-name">
                    <p className="color-name-item">{color.name}</p>
                  </div>
                  <div className="color-code">
                    <ul className="desc-list">
                      {color.codes.map((code, codeIndex) => (
                        <li key={codeIndex} className="desc-item">
                          {code}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </li>
      ))}
    </BrandListWrap>
  );
};

export default BrandList;
