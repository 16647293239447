import { BrandWrap } from "../styles/common/brand.styled";
import { Link } from "react-router-dom";
import brandIntroIcon01 from "../assets/images/brandIcon1.png";
import brandIntroIcon02 from "../assets/images/brandIcon2.png";
import brandIntroIcon03 from "../assets/images/brandIcon3.png";
import brandIntroIcon04 from "../assets/images/brandIcon4.png";
import brandIntroIcon05 from "../assets/images/brandIcon5.png";
import brandIntroIcon06 from "../assets/images/brandIcon6.png";
import brandIntro_img01 from "../assets/images/brandIntro_img01.png";
import brandIntro_img02 from "../assets/images/brandIntro_img02.png";
import brandIntro_img03 from "../assets/images/brandIntro_img03.png";
import brandIntro_img04 from "../assets/images/brandIntro_img04.png";
import brandIntro_img05 from "../assets/images/brandIntro_img05.png";
import brandIntro_img06 from "../assets/images/brandIntro_img06.png";
import brandIntro_img07 from "../assets/images/brandIntro_img07_2th.png";
import brandIntro_img08 from "../assets/images/brandIntro_img08_2th.png";
import brandIntro_img09 from "../assets/images/brandIntro_img09_2th.png";
import brandIntro_img10 from "../assets/images/brandIntro_img10.png";
import brandIntro_img11 from "../assets/images/brandIntro_img11.png";
import brandIntro_img12 from "../assets/images/brandIntro_img12.png";
import brandIntro_img13 from "../assets/images/brandIntro_img13.png";
import brandIntro_img14 from "../assets/images/brandIntro_img14.png";
import brandIntro_img15 from "../assets/images/brandIntro_img15.png";
import brandIntro_img16 from "../assets/images/brandIntro_img16.png";
import brandIntro_img17 from "../assets/images/brandIntro_img17.png";
import brandIntro_img18 from "../assets/images/brandIntro_img18.png";

const Brand = () => {
  const brandData = [
    {
      brandIntroIcon: brandIntroIcon01,
      brandIntroName: "코딩마법학교",
      brandIntroDetail: "최신 IT 기술들을 마법처럼 쉽고 재미있게 체험 학습할 수 있습니다.",
      linkTo: "https://edu.magicoding.io/",
      brandIntroImages: [brandIntro_img01, brandIntro_img02, brandIntro_img03],
    },
    {
      brandIntroIcon: brandIntroIcon02,
      brandIntroName: "라이프해킹스쿨",
      brandIntroDetail:
        "무자본 창업, 심리학, 커머스 등 경제적 자유를 위한 온라인 클래스, 오직 라이프해킹스쿨에서!",
      linkTo: "https://lifehacking.co.kr/",
      brandIntroImages: [brandIntro_img04, brandIntro_img05, brandIntro_img06],
    },
    {
      brandIntroIcon: brandIntroIcon03,
      brandIntroName: "오즈코딩스쿨",
      brandIntroDetail:
        "누구나 마법처럼 빠르게 개발자를 성장시켜주는 코딩 학교! 지금 오즈코딩스쿨에 입학하고 개발자가 되어보세요.",
      linkTo: "https://ozcodingschool.com/",
      brandIntroImages: [brandIntro_img07, brandIntro_img08, brandIntro_img09],
    },
    {
      brandIntroIcon: brandIntroIcon04,
      brandIntroName: "한국 프랜차이즈 파트너스",
      brandIntroDetail:
        "프랜차이즈 전문 마케팅/영업 대행 기업. 가맹 모집에 최적화된 마케팅과 영업 전략",
      linkTo: "https://kfpartners.co.kr/",
      brandIntroImages: [brandIntro_img10, brandIntro_img11, brandIntro_img12],
    },
    {
      brandIntroIcon: brandIntroIcon05,
      brandIntroName: "셀팜",
      brandIntroDetail: "남다른 신선함 셀팜 축산, 국내에서 가장 저렴한 농수산 도매몰",
      linkTo: "https://sellfarm.co.kr/",
      brandIntroImages: [brandIntro_img13, brandIntro_img14, brandIntro_img15],
    },
    {
      brandIntroIcon: brandIntroIcon06,
      brandIntroName: "왓쳐",
      brandIntroDetail:
        "IT 외주 개발은 프로젝트 와쳐에게 맡기고 중요한 비즈니스에 집중하세요. 최저가 견적 산출부터 프로젝트 완료까지.",
      linkTo: "https://watcher.team/",
      brandIntroImages: [brandIntro_img16, brandIntro_img17, brandIntro_img18],
    },
  ];

  return (
    <BrandWrap>
      <div className="section-title">
        <p className="title">Brand.</p>
        <p className="title-desc">
          넥스트러너스는 8 - 14세 어린이부터 2030 세대의 청년층까지 다양한 교육 브랜드를 운영합니다.
        </p>
      </div>
      <ul className="brand-list">
        {brandData.map((brand, index) => (
          <li className="brandIntro-item" key={index}>
            <div className="brandIntro-desc-wrap">
              <div className="brandIntro-desc">
                <div
                  className="brandIntro-icon"
                  style={{ backgroundImage: `url(${brand.brandIntroIcon})` }}
                ></div>
                <div className="brandIntro-text">
                  <p className="brandIntro-name">{brand.brandIntroName}</p>
                  <p className="brandIntro-detail">{brand.brandIntroDetail}</p>
                </div>
              </div>
              <div className="brandIntro-more">
                <Link to={brand.linkTo} className="brandIntro-more-btn">
                  <p>바로가기</p>
                  <p>
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10 7.5L5.5 10.9641L5.5 4.0359L10 7.5Z" fill="#B9B9B9" />
                    </svg>
                  </p>
                </Link>
              </div>
            </div>
            <ul className="brandIntro-img-wrap">
              {brand.brandIntroImages.map((img, imgIndex) => (
                <li
                  key={imgIndex}
                  className="brandIntro-img"
                  style={{ backgroundImage: `url(${img})` }}
                ></li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </BrandWrap>
  );
};

export default Brand;
