import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { HeaderWrap } from "../styles/common/header.styled";
import HamMenuMob from "./hamMenuMob";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [rotateIcon, setRotateIcon] = useState(false);
  const headerWrapRef = useRef(null);
  const gnbListRef = useRef(null);
  const lnbWrapRef = useRef(null);
  const lnbContainerRef = useRef(null);
  const [gnbHover, setGnbHover] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = useCallback(() => {
    setWindowWidth(window.innerWidth);
    adjustWidths();
  }, []);

  const adjustWidths = () => {
    const gnbList = gnbListRef.current;
    const gnbItems = gnbList ? gnbList.querySelectorAll(".gnb-item") : [];
    const lnbLists = document.querySelectorAll(".lnb-container .lnb-list");

    gnbItems.forEach((item, index) => {
      if (lnbLists[index]) {
        lnbLists[index].style.width = `${item.offsetWidth}px`;
      }
    });
  };

  const checkIfHovered = useCallback(
    (e) => {
      if (
        windowWidth > 992 &&
        (headerWrapRef.current.contains(e.target) || lnbContainerRef.current.contains(e.target))
      ) {
        setGnbHover(true);
      } else {
        setGnbHover(false);
      }
    },
    [windowWidth],
  );

  useEffect(() => {
    document.addEventListener("mousemove", checkIfHovered);
    return () => {
      document.removeEventListener("mousemove", checkIfHovered);
    };
  }, [checkIfHovered]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    adjustWidths();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setRotateIcon(!rotateIcon);
  };

  return (
    <HeaderWrap>
      <div className="header-wrap" ref={headerWrapRef}>
        {/* 헤더 */}
        <div className="nxr-logo">
          <a href="/" className="item" alt="넥스트러너스 로고 이미지">
            <svg
              className="pc_only"
              width="280"
              height="32"
              viewBox="0 0 280 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="logo-color"
                d="M1.3749 26.01V4.167H9.5019L17.0349 15.831H17.1429V4.167H24.6759V26.01H16.8999L9.0429 14.049H8.8809V26.01H1.3749ZM27.3729 26.01V4.167H48.298V9.756H35.149V12.483H47.4879V17.694H35.149V20.394H48.676V26.01H27.3729ZM58.1171 26.01H49.7471L57.8741 14.454L50.5301 4.167H58.9001L62.4101 9.864L65.9201 4.167H73.8041L66.5411 14.481L74.6141 26.01H66.0551L62.0591 19.746L58.1171 26.01ZM81.266 25.983V9.756H74.354V4.167H95.846V9.756H88.907V25.983H81.266Z"
                fill="#000"
              />
              <path d="M99.6471 26.01H92.3571V20.529H99.6471V26.01Z" fill="#FB0000" />
              <path
                className="logo-color"
                d="M102.742 26.01V4.167H115.972C123.397 4.167 125.719 8.082 125.719 11.268C125.719 14.184 124.288 16.209 121.885 17.397L128.149 26.01H119.266L114.298 18.666H110.518V26.01H102.742ZM110.518 9.486V13.509H113.731C115.918 13.509 118.051 13.644 118.051 11.511C118.051 9.621 116.296 9.486 114.19 9.486H110.518ZM151.813 4.167V17.451C151.813 23.526 148.195 26.388 140.554 26.388C132.913 26.388 129.241 23.526 129.241 17.451V4.167H136.882V15.966C136.882 18.153 136.855 20.961 140.608 20.961C144.199 20.961 144.199 18.153 144.199 15.966V4.167H151.813ZM154.501 26.01V4.167H162.628L170.161 15.831H170.269V4.167H177.802V26.01H170.026L162.169 14.049H162.007V26.01H154.501ZM180.499 26.01V4.167H188.626L196.159 15.831H196.267V4.167H203.8V26.01H196.024L188.167 14.049H188.005V26.01H180.499ZM206.497 26.01V4.167H227.422V9.756H214.273V12.483H226.612V17.694H214.273V20.394H227.8V26.01H206.497ZM230.491 26.01V4.167H243.721C251.146 4.167 253.468 8.082 253.468 11.268C253.468 14.184 252.037 16.209 249.634 17.397L255.898 26.01H247.015L242.047 18.666H238.267V26.01H230.491ZM238.267 9.486V13.509H241.48C243.667 13.509 245.8 13.644 245.8 11.511C245.8 9.621 244.045 9.486 241.939 9.486H238.267ZM264.172 18.585C264.172 20.934 265.846 21.474 268.033 21.474C269.221 21.474 271.084 21.231 271.084 19.638C271.084 18.423 270.193 18.234 266.872 17.424C266.548 17.343 266.197 17.235 265.792 17.154C261.742 16.155 256.855 15.102 256.855 10.89C256.855 5.463 261.85 3.735 267.115 3.735C272.623 3.735 277.699 5.571 277.726 10.944H270.166C270.328 9.216 268.546 8.595 267.088 8.595C266.089 8.595 264.442 8.811 264.442 10.026C264.442 11.133 266.629 11.565 269.086 12.105L271.597 12.699C275.134 13.509 279.076 15.075 279.076 18.936C279.076 24.579 273.028 26.388 267.331 26.388C264.496 26.388 256.18 25.497 256.18 18.585H264.172Z"
                fill="#000"
              />
            </svg>
            <svg
              className="pc_none"
              xmlns="http://www.w3.org/2000/svg"
              width="122"
              height="10"
              viewBox="0 0 122 10"
              fill="none"
            >
              <path
                d="M34.913 9.76086V2.66986H31.8926V0.227539H41.2843V2.66986H38.2521V9.76086H34.913Z"
                fill="white"
              />
              <path
                d="M24.7963 9.77266H21.1387L24.6901 4.72283L21.4808 0.227539H25.1384L26.6722 2.71706L28.2061 0.227539H31.6513L28.4774 4.73463L32.0052 9.77266H28.2651L26.5189 7.03537L24.7963 9.77266Z"
                fill="white"
              />
              <path
                d="M11.3613 9.77266V0.227539H20.5053V2.66986H14.7593V3.86153H20.1513V6.13867H14.7593V7.31853H20.6705V9.77266H11.3613Z"
                fill="white"
              />
              <path
                d="M0 9.77266V0.227539H3.5514L6.84322 5.32456H6.89042V0.227539H10.1822V9.77266H6.78423L3.35082 4.54585H3.28003V9.77266H0Z"
                fill="white"
              />
              <path d="M42.9415 9.77208H39.7559V7.37695H42.9415V9.77208Z" fill="#FB0000" />
              <path
                d="M115.488 6.54005C115.488 7.56653 116.22 7.8025 117.176 7.8025C117.695 7.8025 118.509 7.69631 118.509 7.00019C118.509 6.46925 118.12 6.38666 116.668 6.0327C116.527 5.99731 116.373 5.95011 116.196 5.91472C114.427 5.47817 112.291 5.01802 112.291 3.17743C112.291 0.805895 114.474 0.0507812 116.775 0.0507812C119.181 0.0507812 121.4 0.85309 121.411 3.20102H118.108C118.179 2.44591 117.4 2.17454 116.763 2.17454C116.326 2.17454 115.606 2.26893 115.606 2.79987C115.606 3.28361 116.562 3.47239 117.636 3.70837L118.733 3.96794C120.279 4.3219 122.001 5.00622 122.001 6.69343C122.001 9.15935 119.358 9.94986 116.869 9.94986C115.63 9.94986 111.996 9.5605 111.996 6.54005H115.488Z"
                fill="white"
              />
              <path
                d="M100.771 9.78437V0.239258H106.553C109.797 0.239258 110.812 1.95006 110.812 3.34231C110.812 4.61656 110.187 5.50146 109.137 6.0206L111.874 9.78437H107.992L105.821 6.57514H104.169V9.78437H100.771ZM104.169 2.56359V4.32159H105.574C106.529 4.32159 107.461 4.38059 107.461 3.44849C107.461 2.62259 106.694 2.56359 105.774 2.56359H104.169Z"
                fill="white"
              />
              <path
                d="M90.2852 9.78437V0.239258H99.4291V2.68158H93.6832V3.87325H99.0752V6.15039H93.6832V7.33025H99.5943V9.78437H90.2852Z"
                fill="white"
              />
              <path
                d="M78.9258 9.78437V0.239258H82.4772L85.769 5.33628H85.8162V0.239258H89.108V9.78437H85.71L82.2766 4.55757H82.2058V9.78437H78.9258Z"
                fill="white"
              />
              <path
                d="M67.5625 9.78437V0.239258H71.1139L74.4057 5.33628H74.4529V0.239258H77.7447V9.78437H74.3467L70.9133 4.55757H70.8425V9.78437H67.5625Z"
                fill="white"
              />
              <path
                d="M66.3891 0.239258V6.0442C66.3891 8.6989 64.8081 9.94956 61.469 9.94956C58.13 9.94956 56.5254 8.6989 56.5254 6.0442V0.239258H59.8644V5.39527C59.8644 6.35096 59.8526 7.57802 61.4926 7.57802C63.0618 7.57802 63.0618 6.35096 63.0618 5.39527V0.239258H66.3891Z"
                fill="white"
              />
              <path
                d="M44.9453 9.78437V0.239258H50.7267C53.9713 0.239258 54.986 1.95006 54.986 3.34231C54.986 4.61656 54.3606 5.50146 53.3106 6.0206L56.0479 9.78437H52.1661L49.9951 6.57514H48.3433V9.78437H44.9453ZM48.3433 2.56359V4.32159H49.7474C50.7031 4.32159 51.6352 4.38059 51.6352 3.44849C51.6352 2.62259 50.8682 2.56359 49.9479 2.56359H48.3433Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <button className="ham-btn pc_none" onClick={toggleMenu}>
          {menuOpen ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                transform: rotateIcon ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            >
              <path
                d="M18 6L6 18M6 6L18 18"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{
                transform: rotateIcon ? "rotate(180deg)" : "rotate(0deg)",
                transition: "transform 0.3s ease",
              }}
            >
              <path
                d="M3 12H21M3 6H21M3 18H21"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </button>
        <nav>
          <ul
            className="gnb-list"
            ref={gnbListRef}
            onMouseEnter={() => setGnbHover(true)}
            onMouseLeave={() => setGnbHover(false)}
          >
            {["About us", "Service", "NXR Team", "Recruit"].map((item, index) => (
              <li
                key={index}
                className="gnb-item"
                onMouseEnter={() => setHoveredIndex(index)} // 마우스 오버 시 인덱스 설정
                onMouseLeave={() => setHoveredIndex(null)} // 마우스가 떠날 때 인덱스 제거
                style={{ color: hoveredIndex === index ? "#fb0000" : "" }}
              >
                {item}
              </li>
            ))}
          </ul>
        </nav>
        <div
          className="lnb-container"
          ref={lnbContainerRef}
          style={{
            visibility: gnbHover ? "visible" : "hidden",
            opacity: gnbHover ? "1" : "0.5",
            maxHeight: gnbHover ? "500px" : "0",
            zIndex: gnbHover ? "1" : "-1",
          }}
        >
          <div className="lnb-area">
            <div className="lnb-wrap" ref={lnbWrapRef}>
              <ul className="lnb-list">
                <li className="lnb-item">
                  <Link to="/vision">Vision</Link>
                </li>
                <li className="lnb-item">
                  <Link to="/history">History</Link>
                </li>
                <li className="lnb-item">
                  <Link to="/ci-bi">CI / BI</Link>
                </li>
              </ul>
              <ul className="lnb-list">
                <li className="lnb-item">
                  <Link to="/brand">Brand</Link>
                </li>
                {/* <li className="lnb-item">NXR LAB</li> */}
              </ul>
              <ul className="lnb-list">
                <li className="lnb-item">
                  <Link to="/culture">Culture</Link>
                </li>
                <li className="lnb-item">
                  <Link to="/benefits">Benefits</Link>
                </li>
                <li className="lnb-item">
                  <Link to="/interviews">Interviews</Link>
                </li>
              </ul>
              <ul className="lnb-list">
                <li className="lnb-item">
                  <Link to="/recruit">Recruit</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <HamMenuMob isOpen={menuOpen} onClose={() => setMenuOpen(false)} />
    </HeaderWrap>
  );
};

export default Header;
