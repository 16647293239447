import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { BrandSwiperWrap } from "../styles/common/brandSwiper.styled";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import sellfarm_default from "../assets/images/sellfarm_default.png";
import sellfarm_hover from "../assets/images/sellfarm_hover.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const BrandSwiper = ({ onSelectTab }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  // const swiperRef = useRef(null);

  const handleSlideClick = (index) => {
    onSelectTab(index);
    setActiveIndex(index);
  };

  const handleSlideChange = (swiper) => {
    // setActiveIndex(swiper.activeIndex);
    // swiper.slideTo(activeIndex);
    // setActiveIndex(swiper.activeIndex);
    // swiper.slideTo(swiper.activeIndex, 300);
  };

  return (
    <BrandSwiperWrap className="brands-swiper-wrap">
      <Swiper
        className="swiper-list"
        // ref={swiperRef}
        modules={[Navigation, Pagination, Scrollbar]}
        spaceBetween={0}
        slidesPerView={"auto"}
        centeredSlides={true}
        loop={true}
        breakpoints={{
          1400: {
            slidesPerView: 4,
            spaceBetween: 40,
            centeredSlides: false,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 40,
            centeredSlides: false,
          },
        }}
        navigation={{
          prevEl: ".prev-btn",
          nextEl: ".next-btn",
        }}
        onSlideChange={handleSlideChange}
      >
        {[
          {
            defaultImg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="211"
                height="48"
                viewBox="0 0 211 48"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.499966 27.5913C1.87901 27.5913 2.97378 27.7808 3.78427 28.1598C4.58266 28.5388 5.16332 29.1325 5.52622 29.9411C5.87703 30.737 6.05243 31.7982 6.05243 33.1247V37.3884C6.05243 40.3446 6.5605 42.5934 7.57664 44.1346C8.58068 45.6759 9.96578 46.7055 11.7319 47.2235C12.4752 47.4415 13.3052 47.6136 14.222 47.7398C14.9538 47.9103 15.7163 48.0004 16.5 48.0004L18.5001 48.0004H46.1004L48.1004 48.0004C48.884 48.0004 49.6465 47.9103 50.3782 47.7399C51.2951 47.6136 52.1253 47.4415 52.8686 47.2235C54.6348 46.7055 56.0198 45.6759 57.0239 44.1346C58.04 42.5934 58.5481 40.3446 58.5481 37.3884V33.1247C58.5481 31.7982 58.7235 30.737 59.0743 29.9411C59.4372 29.1325 60.0179 28.5388 60.8163 28.1598C61.6267 27.7808 62.7215 27.5913 64.1006 27.5913V20.4281C62.7215 20.4281 61.6267 20.2386 60.8163 19.8596C60.0179 19.468 59.4372 18.8742 59.0743 18.0783C58.7235 17.2698 58.5481 16.2023 58.5481 14.8758V10.5931C58.5481 7.6495 58.04 5.40708 57.0239 3.86581C56.0198 2.32454 54.6348 1.29492 52.8686 0.776952C52.1253 0.558946 51.2951 0.386818 50.3782 0.260568C49.6465 0.0901228 48.884 0 48.1004 0H46.1004H18.5001H16.5C15.7163 0 14.9538 0.0901367 14.222 0.260608C13.3052 0.386854 12.4752 0.558969 11.7319 0.776952C9.96578 1.29492 8.58068 2.32454 7.57664 3.86581C6.5605 5.40708 6.05243 7.6495 6.05243 10.5931V14.8758C6.05243 16.2023 5.87703 17.2698 5.52622 18.0783C5.16332 18.8742 4.58266 19.468 3.78427 19.8596C2.97378 20.2386 1.87901 20.4281 0.499966 20.4281V27.5913Z"
                  fill="#414756"
                  fillOpacity="0.6"
                />
                <path
                  d="M37.4004 7.19922L41.3004 11.6993M41.3004 11.6993L44.6004 7.19922M41.3004 11.6993L41.6004 17.6993M41.3004 11.6993L47.0004 13.7993M41.3004 11.6993L35.6003 13.7993"
                  stroke="#414756"
                  strokeWidth="2"
                />
                <path d="M21.2001 39.5998L36.2003 20.0996" stroke="#414756" strokeWidth="3" />
                <path d="M140.014 23.6059V22.1611H149.086V23.6059H140.014Z" fill="#414756" />
                <path
                  d="M134.608 21.2385C131.231 21.2385 128.644 18.9872 128.644 14.8376C128.644 10.7384 131.298 8.33594 134.608 8.33594C136.405 8.33594 137.648 9.10874 138.455 9.93195L137.144 11.5112C136.523 10.9232 135.817 10.4696 134.692 10.4696C132.625 10.4696 131.18 12.0824 131.18 14.7536C131.18 17.4584 132.424 19.088 134.876 19.088C135.464 19.088 136.069 18.92 136.422 18.6176V16.1648H134.221V14.132H138.64V19.7432C137.8 20.5664 136.338 21.2385 134.608 21.2385Z"
                  fill="#414756"
                />
                <path
                  d="M116.681 21.0056V8.55664H119.218L122.83 15.1927L124.073 17.8807H124.157C124.04 16.5871 123.838 14.9407 123.838 13.5295V8.55664H126.19V21.0056H123.67L120.075 14.3527L118.815 11.6815H118.731C118.849 13.0255 119.033 14.5879 119.033 16.0159V21.0056H116.681Z"
                  fill="#414756"
                />
                <path d="M111.136 21.0056V8.55664H113.623V21.0056H111.136Z" fill="#414756" />
                <path
                  d="M99.1431 21.0056V8.55664H102.554C106.35 8.55664 108.702 10.5391 108.702 14.7391C108.702 18.9223 106.35 21.0056 102.688 21.0056H99.1431ZM101.63 19.0063H102.402C104.721 19.0063 106.166 17.7631 106.166 14.7391C106.166 11.6983 104.721 10.5559 102.402 10.5559H101.63V19.0063Z"
                  fill="#414756"
                />
                <path
                  d="M91.1541 21.2385C87.8613 21.2385 85.5932 18.7688 85.5932 14.7368C85.5932 10.688 87.8613 8.33594 91.1541 8.33594C94.4469 8.33594 96.7149 10.7048 96.7149 14.7368C96.7149 18.7688 94.4469 21.2385 91.1541 21.2385ZM91.1541 19.088C93.0021 19.088 94.1781 17.3912 94.1781 14.7368C94.1781 12.0656 93.0021 10.4696 91.1541 10.4696C89.3061 10.4696 88.1301 12.0656 88.1301 14.7368C88.1301 17.3912 89.3061 19.088 91.1541 19.088Z"
                  fill="#414756"
                />
                <path
                  d="M80.9032 21.2385C77.7616 21.2385 75.208 18.9872 75.208 14.8376C75.208 10.7384 77.8456 8.33594 80.9872 8.33594C82.5664 8.33594 83.8265 9.09194 84.6329 9.93195L83.3392 11.5112C82.7008 10.9064 81.9784 10.4696 81.0376 10.4696C79.156 10.4696 77.7448 12.0824 77.7448 14.7536C77.7448 17.4584 79.0216 19.088 80.9872 19.088C82.0624 19.088 82.8856 18.584 83.5577 17.8616L84.8681 19.4072C83.8433 20.6 82.516 21.2385 80.9032 21.2385Z"
                  fill="#414756"
                />
                <path
                  d="M204.267 39.6061V35.0533L200.538 27.1572H203.175L204.418 30.1981C204.771 31.1221 205.107 31.9957 205.494 32.9533H205.561C205.947 31.9957 206.317 31.1221 206.67 30.1981L207.913 27.1572H210.5L206.754 35.0533V39.6061H204.267Z"
                  fill="#414756"
                />
                <path
                  d="M187.828 39.6061V27.1572H190.583L192.7 32.9869C192.969 33.7597 193.204 34.5829 193.456 35.3725H193.54C193.809 34.5829 194.027 33.7597 194.296 32.9869L196.362 27.1572H199.118V39.6061H196.85V34.4149C196.85 33.2389 197.034 31.5085 197.169 30.3325H197.085L196.077 33.2557L194.162 38.4805H192.734L190.802 33.2557L189.81 30.3325H189.743C189.861 31.5085 190.062 33.2389 190.062 34.4149V39.6061H187.828Z"
                  fill="#414756"
                />
                <path
                  d="M177.492 39.6061V27.1572H185.119V29.2404H179.978V32.1133H184.346V34.1965H179.978V37.5229H185.304V39.6061H177.492Z"
                  fill="#414756"
                />
                <path
                  d="M165.499 39.6061V27.1572H168.909C172.706 27.1572 175.058 29.1396 175.058 33.3397C175.058 37.5229 172.706 39.6061 169.044 39.6061H165.499ZM167.985 37.6069H168.758C171.076 37.6069 172.521 36.3637 172.521 33.3397C172.521 30.2989 171.076 29.1564 168.758 29.1564H167.985V37.6069Z"
                  fill="#414756"
                />
                <path
                  d="M157.441 33.1213L157.071 34.4821H160.028L159.659 33.1213C159.272 31.8277 158.936 30.3997 158.583 29.0556H158.499C158.18 30.4165 157.811 31.8277 157.441 33.1213ZM153.14 39.6061L157.122 27.1572H160.045L164.043 39.6061H161.439L160.566 36.4141H156.534L155.66 39.6061H153.14Z"
                  fill="#414756"
                />
                <path
                  d="M148.768 39.839C145.627 39.839 143.073 37.5878 143.073 33.4382C143.073 29.3389 145.711 26.9365 148.852 26.9365C150.431 26.9365 151.691 27.6925 152.498 28.5325L151.204 30.1118C150.566 29.5069 149.843 29.0701 148.903 29.0701C147.021 29.0701 145.61 30.683 145.61 33.3542C145.61 36.059 146.887 37.6886 148.852 37.6886C149.927 37.6886 150.751 37.1846 151.423 36.4622L152.733 38.0078C151.708 39.2006 150.381 39.839 148.768 39.839Z"
                  fill="#414756"
                />
                <path
                  d="M135.834 33.1213L135.464 34.4821H138.421L138.051 33.1213C137.665 31.8277 137.329 30.3997 136.976 29.0556H136.892C136.573 30.4165 136.203 31.8277 135.834 33.1213ZM131.533 39.6061L135.514 27.1572H138.438L142.436 39.6061H139.832L138.958 36.4141H134.926L134.053 39.6061H131.533Z"
                  fill="#414756"
                />
                <path
                  d="M123.355 39.839C120.213 39.839 117.66 37.5878 117.66 33.4382C117.66 29.3389 120.297 26.9365 123.439 26.9365C125.018 26.9365 126.278 27.6925 127.084 28.5325L125.791 30.1118C125.152 29.5069 124.43 29.0701 123.489 29.0701C121.608 29.0701 120.196 30.683 120.196 33.3542C120.196 36.059 121.473 37.6886 123.439 37.6886C124.514 37.6886 125.337 37.1846 126.009 36.4622L127.32 38.0078C126.295 39.2006 124.968 39.839 123.355 39.839Z"
                  fill="#414756"
                />
                <path d="M112.736 39.6061V27.1572H115.222V39.6061H112.736Z" fill="#414756" />
                <path
                  d="M106.036 39.839C102.659 39.839 100.072 37.5878 100.072 33.4382C100.072 29.3389 102.726 26.9365 106.036 26.9365C107.834 26.9365 109.077 27.7093 109.883 28.5325L108.573 30.1118C107.951 29.5237 107.246 29.0701 106.12 29.0701C104.053 29.0701 102.609 30.683 102.609 33.3542C102.609 36.059 103.852 37.6886 106.305 37.6886C106.893 37.6886 107.498 37.5206 107.85 37.2182V34.7654H105.649V32.7326H110.068V38.3438C109.228 39.167 107.766 39.839 106.036 39.839Z"
                  fill="#414756"
                />
                <path
                  d="M92.8325 33.1213L92.4629 34.4821H95.4198L95.0502 33.1213C94.6638 31.8277 94.3278 30.3997 93.975 29.0556H93.891C93.5718 30.4165 93.2021 31.8277 92.8325 33.1213ZM88.5317 39.6061L92.5133 27.1572H95.4366L99.435 39.6061H96.831L95.9574 36.4141H91.9253L91.0517 39.6061H88.5317Z"
                  fill="#414756"
                />
                <path
                  d="M75.7886 39.6061V27.1572H78.5439L80.6607 32.9869C80.9295 33.7597 81.1647 34.5829 81.4167 35.3725H81.5007C81.7695 34.5829 81.9879 33.7597 82.2567 32.9869L84.3231 27.1572H87.0783V39.6061H84.8103V34.4149C84.8103 33.2389 84.9951 31.5085 85.1295 30.3325H85.0455L84.0375 33.2557L82.1223 38.4805H80.6943L78.7623 33.2557L77.7711 30.3325H77.7039C77.8215 31.5085 78.0231 33.2389 78.0231 34.4149V39.6061H75.7886Z"
                  fill="#414756"
                />
              </svg>
            ),
            defaultImgMob: (
              <svg
                width="167"
                height="38"
                viewBox="0 0 167 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 21.8429C1.09173 21.8429 1.95842 21.9929 2.60005 22.2929C3.23211 22.593 3.69179 23.063 3.97908 23.7031C4.25681 24.3332 4.39567 25.1733 4.39567 26.2235V29.5989C4.39567 31.9392 4.79788 33.7194 5.60232 34.9396C6.39718 36.1598 7.4937 36.9749 8.89189 37.3849C10.2901 37.795 12.0761 38 14.25 38V37.9877C14.4157 37.9959 14.5824 38 14.7501 38H35.6001C35.7676 38 35.9341 37.9959 36.0995 37.9877V38C38.2734 38 40.0594 37.795 41.4576 37.3849C42.8558 36.9749 43.9523 36.1598 44.7472 34.9396C45.5516 33.7194 45.9538 31.9392 45.9538 29.5989V26.2235C45.9538 25.1733 46.0927 24.3332 46.3704 23.7031C46.6577 23.063 47.1174 22.593 47.7494 22.2929C48.3911 21.9929 49.2578 21.8429 50.3495 21.8429V16.1721C49.2578 16.1721 48.3911 16.0221 47.7494 15.7221C47.1174 15.412 46.6577 14.942 46.3704 14.3119C46.0927 13.6718 45.9538 12.8267 45.9538 11.7766V8.3861C45.9538 6.0558 45.5516 4.28056 44.7472 3.0604C43.9523 1.84024 42.8558 1.02514 41.4576 0.615081C40.0594 0.205027 38.2734 0 36.0995 0V0.012254C35.9341 0.00411654 35.7675 0 35.6001 0H14.7501C14.5824 0 14.4157 0.00412756 14.25 0.0122866V0C12.0761 0 10.2901 0.205027 8.89189 0.615081C7.4937 1.02514 6.39718 1.84024 5.60232 3.0604C4.79788 4.28056 4.39567 6.0558 4.39567 8.3861V11.7766C4.39567 12.8267 4.25681 13.6718 3.97908 14.3119C3.69179 14.942 3.23211 15.412 2.60005 15.7221C1.95842 16.0221 1.09173 16.1721 0 16.1721V21.8429Z"
                  fill="#414756"
                  fillOpacity="0.7"
                />
                <path
                  d="M29.2121 5.69922L32.2996 9.26172M32.2996 9.26172L34.9121 5.69922M32.2996 9.26172L32.5371 14.0117M32.2996 9.26172L36.8121 10.9242M32.2996 9.26172L27.7871 10.9242"
                  stroke="#414756"
                  strokeWidth="2.5"
                />
                <path d="M16.3867 31.3496L28.2617 15.9121" stroke="#414756" strokeWidth="3" />
                <path d="M110.447 18.6868V17.543H117.629V18.6868H110.447Z" fill="#414756" />
                <path
                  d="M106.167 16.8121C103.494 16.8121 101.445 15.0299 101.445 11.7448C101.445 8.49956 103.547 6.59766 106.167 6.59766C107.59 6.59766 108.574 7.20946 109.213 7.86116L108.175 9.11136C107.683 8.64586 107.124 8.28676 106.233 8.28676C104.597 8.28676 103.454 9.56356 103.454 11.6783C103.454 13.8196 104.438 15.1097 106.38 15.1097C106.845 15.1097 107.324 14.9767 107.603 14.7373V12.7955H105.861V11.1862H109.359V15.6284C108.694 16.2801 107.537 16.8121 106.167 16.8121Z"
                  fill="#414756"
                />
                <path
                  d="M91.9746 16.6268V6.77148H93.9829L96.8424 12.025L97.8266 14.153H97.8931C97.8 13.1289 97.6404 11.8255 97.6404 10.7083V6.77148H99.5024V16.6268H97.5074L94.6612 11.36L93.6637 9.24528H93.5972C93.6903 10.3093 93.8366 11.5462 93.8366 12.6767V16.6268H91.9746Z"
                  fill="#414756"
                />
                <path d="M87.5859 16.6268V6.77148H89.5543V16.6268H87.5859Z" fill="#414756" />
                <path
                  d="M78.0918 16.6268V6.77148H80.7917C83.7975 6.77148 85.6595 8.34088 85.6595 11.6659C85.6595 14.9776 83.7975 16.6268 80.8981 16.6268H78.0918ZM80.0602 15.0441H80.672C82.5074 15.0441 83.6512 14.0599 83.6512 11.6659C83.6512 9.25858 82.5074 8.35418 80.672 8.35418H80.0602V15.0441Z"
                  fill="#414756"
                />
                <path
                  d="M71.7656 16.8121C69.1588 16.8121 67.3633 14.857 67.3633 11.665C67.3633 8.45966 69.1588 6.59766 71.7656 6.59766C74.3724 6.59766 76.1679 8.47296 76.1679 11.665C76.1679 14.857 74.3724 16.8121 71.7656 16.8121ZM71.7656 15.1097C73.2286 15.1097 74.1596 13.7664 74.1596 11.665C74.1596 9.55026 73.2286 8.28676 71.7656 8.28676C70.3026 8.28676 69.3716 9.55026 69.3716 11.665C69.3716 13.7664 70.3026 15.1097 71.7656 15.1097Z"
                  fill="#414756"
                />
                <path
                  d="M63.6513 16.8121C61.1642 16.8121 59.1426 15.0299 59.1426 11.7448C59.1426 8.49956 61.2307 6.59766 63.7178 6.59766C64.968 6.59766 65.9655 7.19616 66.6039 7.86116L65.5798 9.11136C65.0744 8.63256 64.5025 8.28676 63.7577 8.28676C62.2681 8.28676 61.1509 9.56356 61.1509 11.6783C61.1509 13.8196 62.1617 15.1097 63.7178 15.1097C64.569 15.1097 65.2207 14.7107 65.7527 14.1388L66.7901 15.3624C65.9788 16.3067 64.9281 16.8121 63.6513 16.8121Z"
                  fill="#414756"
                />
                <path
                  d="M161.314 31.3514V27.7471L158.361 21.4961H160.449L161.434 23.9034C161.713 24.6349 161.979 25.3265 162.285 26.0846H162.338C162.644 25.3265 162.937 24.6349 163.216 23.9034L164.2 21.4961H166.248L163.282 27.7471V31.3514H161.314Z"
                  fill="#414756"
                />
                <path
                  d="M148.299 31.3514V21.4961H150.48L152.156 26.1112C152.369 26.723 152.555 27.3747 152.754 27.9998H152.821C153.034 27.3747 153.207 26.723 153.419 26.1112L155.055 21.4961H157.236V31.3514H155.441V27.2417C155.441 26.3107 155.587 24.9408 155.694 24.0098H155.627L154.829 26.324L153.313 30.4603H152.182L150.653 26.324L149.868 24.0098H149.815C149.908 24.9408 150.068 26.3107 150.068 27.2417V31.3514H148.299Z"
                  fill="#414756"
                />
                <path
                  d="M140.115 31.3514V21.4961H146.153V23.1453H142.084V25.4196H145.542V27.0688H142.084V29.7022H146.3V31.3514H140.115Z"
                  fill="#414756"
                />
                <path
                  d="M130.623 31.3514V21.4961H133.323C136.329 21.4961 138.191 23.0655 138.191 26.3905C138.191 29.7022 136.329 31.3514 133.429 31.3514H130.623ZM132.591 29.7687H133.203C135.039 29.7687 136.182 28.7845 136.182 26.3905C136.182 23.9832 135.039 23.0788 133.203 23.0788H132.591V29.7687Z"
                  fill="#414756"
                />
                <path
                  d="M124.243 26.2176L123.95 27.2949H126.291L125.998 26.2176C125.692 25.1935 125.426 24.063 125.147 22.999H125.081C124.828 24.0763 124.535 25.1935 124.243 26.2176ZM120.838 31.3514L123.99 21.4961H126.304L129.47 31.3514H127.408L126.716 28.8244H123.524L122.833 31.3514H120.838Z"
                  fill="#414756"
                />
                <path
                  d="M117.378 31.5367C114.891 31.5367 112.869 29.7545 112.869 26.4694C112.869 23.2242 114.957 21.3223 117.444 21.3223C118.695 21.3223 119.692 21.9208 120.33 22.5858L119.306 23.836C118.801 23.3572 118.229 23.0114 117.484 23.0114C115.995 23.0114 114.877 24.2882 114.877 26.4029C114.877 28.5442 115.888 29.8343 117.444 29.8343C118.296 29.8343 118.947 29.4353 119.479 28.8634L120.517 30.087C119.705 31.0313 118.655 31.5367 117.378 31.5367Z"
                  fill="#414756"
                />
                <path
                  d="M107.137 26.2176L106.845 27.2949H109.185L108.893 26.2176C108.587 25.1935 108.321 24.063 108.042 22.999H107.975C107.722 24.0763 107.43 25.1935 107.137 26.2176ZM103.732 31.3514L106.885 21.4961H109.199L112.364 31.3514H110.303L109.611 28.8244H106.419L105.727 31.3514H103.732Z"
                  fill="#414756"
                />
                <path
                  d="M97.2587 31.5367C94.7716 31.5367 92.75 29.7545 92.75 26.4694C92.75 23.2242 94.8381 21.3223 97.3252 21.3223C98.5754 21.3223 99.5729 21.9208 100.211 22.5858L99.1872 23.836C98.6818 23.3572 98.1099 23.0114 97.3651 23.0114C95.8755 23.0114 94.7583 24.2882 94.7583 26.4029C94.7583 28.5442 95.7691 29.8343 97.3252 29.8343C98.1764 29.8343 98.8281 29.4353 99.3601 28.8634L100.397 30.087C99.5862 31.0313 98.5355 31.5367 97.2587 31.5367Z"
                  fill="#414756"
                />
                <path d="M88.8516 31.3514V21.4961H90.82V31.3514H88.8516Z" fill="#414756" />
                <path
                  d="M83.5477 31.5367C80.8744 31.5367 78.8262 29.7545 78.8262 26.4694C78.8262 23.2242 80.9276 21.3223 83.5477 21.3223C84.9708 21.3223 85.955 21.9341 86.5934 22.5858L85.556 23.836C85.0639 23.3705 84.5053 23.0114 83.6142 23.0114C81.9783 23.0114 80.8345 24.2882 80.8345 26.4029C80.8345 28.5442 81.8187 29.8343 83.7605 29.8343C84.226 29.8343 84.7048 29.7013 84.9841 29.4619V27.5201H83.2418V25.9108H86.7397V30.353C86.0747 31.0047 84.9176 31.5367 83.5477 31.5367Z"
                  fill="#414756"
                />
                <path
                  d="M73.0943 26.2176L72.8017 27.2949H75.1425L74.8499 26.2176C74.544 25.1935 74.278 24.063 73.9987 22.999H73.9322C73.6795 24.0763 73.3869 25.1935 73.0943 26.2176ZM69.6895 31.3514L72.8416 21.4961H75.1558L78.3212 31.3514H76.2597L75.5681 28.8244H72.3761L71.6845 31.3514H69.6895Z"
                  fill="#414756"
                />
                <path
                  d="M59.6016 31.3514V21.4961H61.7828L63.4586 26.1112C63.6714 26.723 63.8576 27.3747 64.0571 27.9998H64.1236C64.3364 27.3747 64.5093 26.723 64.7221 26.1112L66.358 21.4961H68.5392V31.3514H66.7437V27.2417C66.7437 26.3107 66.89 24.9408 66.9964 24.0098H66.9299L66.1319 26.324L64.6157 30.4603H63.4852L61.9557 26.324L61.171 24.0098H61.1178C61.2109 24.9408 61.3705 26.3107 61.3705 27.2417V31.3514H59.6016Z"
                  fill="#414756"
                />
              </svg>
            ),
            hoverImg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="211"
                height="48"
                viewBox="0 0 211 48"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0.5 27.5913C1.87904 27.5913 2.97381 27.7808 3.7843 28.1598C4.5827 28.5388 5.16335 29.1325 5.52626 29.9411C5.87707 30.737 6.05247 31.7982 6.05247 33.1247V37.3884C6.05247 40.3446 6.56054 42.5934 7.57668 44.1346C8.58072 45.6759 9.96581 46.7055 11.732 47.2235C12.4752 47.4415 13.3053 47.6136 14.2221 47.7398C14.9538 47.9103 15.7164 48.0004 16.5 48.0004L18.5002 48.0004H46.1004L48.1005 48.0004C48.884 48.0004 49.6466 47.9103 50.3783 47.7399C51.2952 47.6136 52.1253 47.4415 52.8686 47.2235C54.6348 46.7055 56.0199 45.6759 57.0239 44.1346C58.0401 42.5934 58.5481 40.3446 58.5481 37.3884V33.1247C58.5481 31.7982 58.7235 30.737 59.0743 29.9411C59.4372 29.1325 60.0179 28.5388 60.8163 28.1598C61.6268 27.7808 62.7215 27.5913 64.1006 27.5913V20.4281C62.7215 20.4281 61.6268 20.2386 60.8163 19.8596C60.0179 19.468 59.4372 18.8742 59.0743 18.0783C58.7235 17.2698 58.5481 16.2023 58.5481 14.8758V10.5931C58.5481 7.6495 58.0401 5.40708 57.0239 3.86581C56.0199 2.32454 54.6348 1.29492 52.8686 0.776952C52.1253 0.558946 51.2952 0.386818 50.3782 0.260568C49.6466 0.0901228 48.884 0 48.1005 0H46.1004H18.5002H16.5C15.7164 0 14.9538 0.0901367 14.2221 0.260608C13.3053 0.386854 12.4752 0.558969 11.732 0.776952C9.96581 1.29492 8.58072 2.32454 7.57668 3.86581C6.56054 5.40708 6.05247 7.6495 6.05247 10.5931V14.8758C6.05247 16.2023 5.87707 17.2698 5.52626 18.0783C5.16335 18.8742 4.5827 19.468 3.7843 19.8596C2.97381 20.2386 1.87904 20.4281 0.5 20.4281V27.5913Z"
                  fill="black"
                />
                <path
                  d="M37.4004 7.19922L41.3004 11.6993M41.3004 11.6993L44.6004 7.19922M41.3004 11.6993L41.6004 17.6993M41.3004 11.6993L47.0004 13.7993M41.3004 11.6993L35.6003 13.7993"
                  stroke="white"
                  strokeWidth="2"
                />
                <path d="M21.2002 39.5998L36.2003 20.0996" stroke="white" strokeWidth="3" />
                <path d="M140.014 23.6059V22.1611H149.086V23.6059H140.014Z" fill="black" />
                <path
                  d="M134.608 21.2385C131.231 21.2385 128.644 18.9872 128.644 14.8376C128.644 10.7384 131.298 8.33594 134.608 8.33594C136.405 8.33594 137.649 9.10874 138.455 9.93195L137.145 11.5112C136.523 10.9232 135.817 10.4696 134.692 10.4696C132.625 10.4696 131.18 12.0824 131.18 14.7536C131.18 17.4584 132.424 19.088 134.877 19.088C135.465 19.088 136.069 18.92 136.422 18.6176V16.1648H134.221V14.132H138.64V19.7432C137.8 20.5664 136.338 21.2385 134.608 21.2385Z"
                  fill="black"
                />
                <path
                  d="M116.681 21.0056V8.55664H119.218L122.83 15.1927L124.073 17.8807H124.157C124.04 16.5871 123.838 14.9407 123.838 13.5295V8.55664H126.19V21.0056H123.67L120.075 14.3527L118.815 11.6815H118.731C118.849 13.0255 119.033 14.5879 119.033 16.0159V21.0056H116.681Z"
                  fill="black"
                />
                <path d="M111.136 21.0056V8.55664H113.623V21.0056H111.136Z" fill="black" />
                <path
                  d="M99.1431 21.0056V8.55664H102.553C106.35 8.55664 108.702 10.5391 108.702 14.7391C108.702 18.9223 106.35 21.0056 102.688 21.0056H99.1431ZM101.629 19.0063H102.402C104.721 19.0063 106.166 17.7631 106.166 14.7391C106.166 11.6983 104.721 10.5559 102.402 10.5559H101.629V19.0063Z"
                  fill="black"
                />
                <path
                  d="M91.1541 21.2385C87.8613 21.2385 85.5933 18.7688 85.5933 14.7368C85.5933 10.688 87.8613 8.33594 91.1541 8.33594C94.4469 8.33594 96.715 10.7048 96.715 14.7368C96.715 18.7688 94.4469 21.2385 91.1541 21.2385ZM91.1541 19.088C93.0021 19.088 94.1781 17.3912 94.1781 14.7368C94.1781 12.0656 93.0021 10.4696 91.1541 10.4696C89.3061 10.4696 88.1301 12.0656 88.1301 14.7368C88.1301 17.3912 89.3061 19.088 91.1541 19.088Z"
                  fill="black"
                />
                <path
                  d="M80.9033 21.2385C77.7616 21.2385 75.208 18.9872 75.208 14.8376C75.208 10.7384 77.8456 8.33594 80.9873 8.33594C82.5665 8.33594 83.8265 9.09194 84.6329 9.93195L83.3393 11.5112C82.7009 10.9064 81.9785 10.4696 81.0377 10.4696C79.156 10.4696 77.7448 12.0824 77.7448 14.7536C77.7448 17.4584 79.0216 19.088 80.9873 19.088C82.0625 19.088 82.8857 18.584 83.5577 17.8616L84.8681 19.4072C83.8433 20.6 82.5161 21.2385 80.9033 21.2385Z"
                  fill="black"
                />
                <path
                  d="M204.267 39.6061V35.0533L200.538 27.1572H203.175L204.418 30.1981C204.771 31.1221 205.107 31.9957 205.494 32.9533H205.561C205.947 31.9957 206.317 31.1221 206.67 30.1981L207.913 27.1572H210.5L206.754 35.0533V39.6061H204.267Z"
                  fill="black"
                />
                <path
                  d="M187.828 39.6061V27.1572H190.583L192.7 32.9869C192.969 33.7597 193.204 34.5829 193.456 35.3725H193.54C193.809 34.5829 194.027 33.7597 194.296 32.9869L196.362 27.1572H199.118V39.6061H196.85V34.4149C196.85 33.2389 197.034 31.5085 197.169 30.3325H197.085L196.077 33.2557L194.162 38.4805H192.734L190.802 33.2557L189.81 30.3325H189.743C189.861 31.5085 190.062 33.2389 190.062 34.4149V39.6061H187.828Z"
                  fill="black"
                />
                <path
                  d="M177.492 39.6061V27.1572H185.119V29.2404H179.978V32.1133H184.346V34.1965H179.978V37.5229H185.304V39.6061H177.492Z"
                  fill="black"
                />
                <path
                  d="M165.499 39.6061V27.1572H168.909C172.706 27.1572 175.058 29.1396 175.058 33.3397C175.058 37.5229 172.706 39.6061 169.044 39.6061H165.499ZM167.985 37.6069H168.758C171.077 37.6069 172.521 36.3637 172.521 33.3397C172.521 30.2989 171.077 29.1564 168.758 29.1564H167.985V37.6069Z"
                  fill="black"
                />
                <path
                  d="M157.441 33.1213L157.071 34.4821H160.028L159.659 33.1213C159.272 31.8277 158.936 30.3997 158.584 29.0556H158.5C158.18 30.4165 157.811 31.8277 157.441 33.1213ZM153.14 39.6061L157.122 27.1572H160.045L164.044 39.6061H161.44L160.566 36.4141H156.534L155.66 39.6061H153.14Z"
                  fill="black"
                />
                <path
                  d="M148.768 39.839C145.627 39.839 143.073 37.5878 143.073 33.4382C143.073 29.3389 145.711 26.9365 148.852 26.9365C150.431 26.9365 151.691 27.6925 152.498 28.5325L151.204 30.1118C150.566 29.5069 149.843 29.0701 148.903 29.0701C147.021 29.0701 145.61 30.683 145.61 33.3542C145.61 36.059 146.887 37.6886 148.852 37.6886C149.927 37.6886 150.751 37.1846 151.423 36.4622L152.733 38.0078C151.708 39.2006 150.381 39.839 148.768 39.839Z"
                  fill="black"
                />
                <path
                  d="M135.834 33.1213L135.464 34.4821H138.421L138.051 33.1213C137.665 31.8277 137.329 30.3997 136.976 29.0556H136.892C136.573 30.4165 136.203 31.8277 135.834 33.1213ZM131.533 39.6061L135.514 27.1572H138.438L142.436 39.6061H139.832L138.959 36.4141H134.926L134.053 39.6061H131.533Z"
                  fill="black"
                />
                <path
                  d="M123.355 39.839C120.213 39.839 117.66 37.5878 117.66 33.4382C117.66 29.3389 120.297 26.9365 123.439 26.9365C125.018 26.9365 126.278 27.6925 127.085 28.5325L125.791 30.1118C125.153 29.5069 124.43 29.0701 123.489 29.0701C121.608 29.0701 120.196 30.683 120.196 33.3542C120.196 36.059 121.473 37.6886 123.439 37.6886C124.514 37.6886 125.337 37.1846 126.009 36.4622L127.32 38.0078C126.295 39.2006 124.968 39.839 123.355 39.839Z"
                  fill="black"
                />
                <path d="M112.736 39.6061V27.1572H115.222V39.6061H112.736Z" fill="black" />
                <path
                  d="M106.036 39.839C102.659 39.839 100.072 37.5878 100.072 33.4382C100.072 29.3389 102.726 26.9365 106.036 26.9365C107.834 26.9365 109.077 27.7093 109.883 28.5325L108.573 30.1118C107.951 29.5237 107.246 29.0701 106.12 29.0701C104.054 29.0701 102.609 30.683 102.609 33.3542C102.609 36.059 103.852 37.6886 106.305 37.6886C106.893 37.6886 107.498 37.5206 107.85 37.2182V34.7654H105.65V32.7326H110.068V38.3438C109.228 39.167 107.766 39.839 106.036 39.839Z"
                  fill="black"
                />
                <path
                  d="M92.8326 33.1213L92.463 34.4821H95.4198L95.0502 33.1213C94.6638 31.8277 94.3278 30.3997 93.975 29.0556H93.891C93.5718 30.4165 93.2022 31.8277 92.8326 33.1213ZM88.5317 39.6061L92.5134 27.1572H95.4366L99.435 39.6061H96.831L95.9574 36.4141H91.9254L91.0518 39.6061H88.5317Z"
                  fill="black"
                />
                <path
                  d="M75.7887 39.6061V27.1572H78.5439L80.6607 32.9869C80.9295 33.7597 81.1647 34.5829 81.4167 35.3725H81.5007C81.7695 34.5829 81.9879 33.7597 82.2568 32.9869L84.3232 27.1572H87.0784V39.6061H84.8104V34.4149C84.8104 33.2389 84.9952 31.5085 85.1296 30.3325H85.0456L84.0376 33.2557L82.1224 38.4805H80.6943L78.7623 33.2557L77.7711 30.3325H77.7039C77.8215 31.5085 78.0231 33.2389 78.0231 34.4149V39.6061H75.7887Z"
                  fill="black"
                />
              </svg>
            ),
            hoverImgMob: (
              <svg
                width="167"
                height="38"
                viewBox="0 0 167 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M0 21.8429C1.09173 21.8429 1.95842 21.9929 2.60005 22.2929C3.23211 22.593 3.69179 23.063 3.97908 23.7031C4.25681 24.3332 4.39567 25.1733 4.39567 26.2235V29.5989C4.39567 31.9392 4.79788 33.7194 5.60232 34.9396C6.39718 36.1598 7.4937 36.9749 8.89189 37.3849C10.2901 37.795 12.0761 38 14.25 38V37.9877C14.4157 37.9959 14.5824 38 14.7501 38H35.6001C35.7676 38 35.9341 37.9959 36.0995 37.9877V38C38.2734 38 40.0594 37.795 41.4576 37.3849C42.8558 36.9749 43.9523 36.1598 44.7472 34.9396C45.5516 33.7194 45.9538 31.9392 45.9538 29.5989V26.2235C45.9538 25.1733 46.0927 24.3332 46.3704 23.7031C46.6577 23.063 47.1174 22.593 47.7494 22.2929C48.3911 21.9929 49.2578 21.8429 50.3495 21.8429V16.1721C49.2578 16.1721 48.3911 16.0221 47.7494 15.7221C47.1174 15.412 46.6577 14.942 46.3704 14.3119C46.0927 13.6718 45.9538 12.8267 45.9538 11.7766V8.3861C45.9538 6.0558 45.5516 4.28056 44.7472 3.0604C43.9523 1.84024 42.8558 1.02514 41.4576 0.615081C40.0594 0.205027 38.2734 0 36.0995 0V0.012254C35.9341 0.00411654 35.7675 0 35.6001 0H14.7501C14.5824 0 14.4157 0.00412756 14.25 0.0122866V0C12.0761 0 10.2901 0.205027 8.89189 0.615081C7.4937 1.02514 6.39718 1.84024 5.60232 3.0604C4.79788 4.28056 4.39567 6.0558 4.39567 8.3861V11.7766C4.39567 12.8267 4.25681 13.6718 3.97908 14.3119C3.69179 14.942 3.23211 15.412 2.60005 15.7221C1.95842 16.0221 1.09173 16.1721 0 16.1721V21.8429Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M29.2121 5.69922L32.2996 9.26172M32.2996 9.26172L34.9121 5.69922M32.2996 9.26172L32.5371 14.0117M32.2996 9.26172L36.8121 10.9242M32.2996 9.26172L27.7871 10.9242"
                  stroke="white"
                  strokeWidth="2.5"
                />
                <path d="M16.3867 31.3496L28.2617 15.9121" stroke="white" strokeWidth="3" />
                <path
                  d="M110.447 18.6868V17.543H117.629V18.6868H110.447Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M106.167 16.8121C103.494 16.8121 101.445 15.0299 101.445 11.7448C101.445 8.49956 103.547 6.59766 106.167 6.59766C107.59 6.59766 108.574 7.20946 109.213 7.86116L108.175 9.11136C107.683 8.64586 107.124 8.28676 106.233 8.28676C104.597 8.28676 103.454 9.56356 103.454 11.6783C103.454 13.8196 104.438 15.1097 106.38 15.1097C106.845 15.1097 107.324 14.9767 107.603 14.7373V12.7955H105.861V11.1862H109.359V15.6284C108.694 16.2801 107.537 16.8121 106.167 16.8121Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M91.9746 16.6268V6.77148H93.9829L96.8424 12.025L97.8266 14.153H97.8931C97.8 13.1289 97.6404 11.8255 97.6404 10.7083V6.77148H99.5024V16.6268H97.5074L94.6612 11.36L93.6637 9.24528H93.5972C93.6903 10.3093 93.8366 11.5462 93.8366 12.6767V16.6268H91.9746Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M87.5859 16.6268V6.77148H89.5543V16.6268H87.5859Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M78.0918 16.6268V6.77148H80.7917C83.7975 6.77148 85.6595 8.34088 85.6595 11.6659C85.6595 14.9776 83.7975 16.6268 80.8981 16.6268H78.0918ZM80.0602 15.0441H80.672C82.5074 15.0441 83.6512 14.0599 83.6512 11.6659C83.6512 9.25858 82.5074 8.35418 80.672 8.35418H80.0602V15.0441Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M71.7656 16.8121C69.1588 16.8121 67.3633 14.857 67.3633 11.665C67.3633 8.45966 69.1588 6.59766 71.7656 6.59766C74.3724 6.59766 76.1679 8.47296 76.1679 11.665C76.1679 14.857 74.3724 16.8121 71.7656 16.8121ZM71.7656 15.1097C73.2286 15.1097 74.1596 13.7664 74.1596 11.665C74.1596 9.55026 73.2286 8.28676 71.7656 8.28676C70.3026 8.28676 69.3716 9.55026 69.3716 11.665C69.3716 13.7664 70.3026 15.1097 71.7656 15.1097Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M63.6513 16.8121C61.1642 16.8121 59.1426 15.0299 59.1426 11.7448C59.1426 8.49956 61.2307 6.59766 63.7178 6.59766C64.968 6.59766 65.9655 7.19616 66.6039 7.86116L65.5798 9.11136C65.0744 8.63256 64.5025 8.28676 63.7577 8.28676C62.2681 8.28676 61.1509 9.56356 61.1509 11.6783C61.1509 13.8196 62.1617 15.1097 63.7178 15.1097C64.569 15.1097 65.2207 14.7107 65.7527 14.1388L66.7901 15.3624C65.9788 16.3067 64.9281 16.8121 63.6513 16.8121Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M161.314 31.3514V27.7471L158.361 21.4961H160.449L161.434 23.9034C161.713 24.6349 161.979 25.3265 162.285 26.0846H162.338C162.644 25.3265 162.937 24.6349 163.216 23.9034L164.2 21.4961H166.248L163.282 27.7471V31.3514H161.314Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M148.299 31.3514V21.4961H150.48L152.156 26.1112C152.369 26.723 152.555 27.3747 152.754 27.9998H152.821C153.034 27.3747 153.207 26.723 153.419 26.1112L155.055 21.4961H157.236V31.3514H155.441V27.2417C155.441 26.3107 155.587 24.9408 155.694 24.0098H155.627L154.829 26.324L153.313 30.4603H152.182L150.653 26.324L149.868 24.0098H149.815C149.908 24.9408 150.068 26.3107 150.068 27.2417V31.3514H148.299Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M140.115 31.3514V21.4961H146.153V23.1453H142.084V25.4196H145.542V27.0688H142.084V29.7022H146.3V31.3514H140.115Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M130.623 31.3514V21.4961H133.323C136.329 21.4961 138.191 23.0655 138.191 26.3905C138.191 29.7022 136.329 31.3514 133.429 31.3514H130.623ZM132.591 29.7687H133.203C135.039 29.7687 136.182 28.7845 136.182 26.3905C136.182 23.9832 135.039 23.0788 133.203 23.0788H132.591V29.7687Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M124.243 26.2176L123.95 27.2949H126.291L125.998 26.2176C125.692 25.1935 125.426 24.063 125.147 22.999H125.081C124.828 24.0763 124.535 25.1935 124.243 26.2176ZM120.838 31.3514L123.99 21.4961H126.304L129.47 31.3514H127.408L126.716 28.8244H123.524L122.833 31.3514H120.838Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M117.378 31.5367C114.891 31.5367 112.869 29.7545 112.869 26.4694C112.869 23.2242 114.957 21.3223 117.444 21.3223C118.695 21.3223 119.692 21.9208 120.33 22.5858L119.306 23.836C118.801 23.3572 118.229 23.0114 117.484 23.0114C115.995 23.0114 114.877 24.2882 114.877 26.4029C114.877 28.5442 115.888 29.8343 117.444 29.8343C118.296 29.8343 118.947 29.4353 119.479 28.8634L120.517 30.087C119.705 31.0313 118.655 31.5367 117.378 31.5367Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M107.137 26.2176L106.845 27.2949H109.185L108.893 26.2176C108.587 25.1935 108.321 24.063 108.042 22.999H107.975C107.722 24.0763 107.43 25.1935 107.137 26.2176ZM103.732 31.3514L106.885 21.4961H109.199L112.364 31.3514H110.303L109.611 28.8244H106.419L105.727 31.3514H103.732Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M97.2587 31.5367C94.7716 31.5367 92.75 29.7545 92.75 26.4694C92.75 23.2242 94.8381 21.3223 97.3252 21.3223C98.5754 21.3223 99.5729 21.9208 100.211 22.5858L99.1872 23.836C98.6818 23.3572 98.1099 23.0114 97.3651 23.0114C95.8755 23.0114 94.7583 24.2882 94.7583 26.4029C94.7583 28.5442 95.7691 29.8343 97.3252 29.8343C98.1764 29.8343 98.8281 29.4353 99.3601 28.8634L100.397 30.087C99.5862 31.0313 98.5355 31.5367 97.2587 31.5367Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M88.8516 31.3514V21.4961H90.82V31.3514H88.8516Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M83.5477 31.5367C80.8744 31.5367 78.8262 29.7545 78.8262 26.4694C78.8262 23.2242 80.9276 21.3223 83.5477 21.3223C84.9708 21.3223 85.955 21.9341 86.5934 22.5858L85.556 23.836C85.0639 23.3705 84.5053 23.0114 83.6142 23.0114C81.9783 23.0114 80.8345 24.2882 80.8345 26.4029C80.8345 28.5442 81.8187 29.8343 83.7605 29.8343C84.226 29.8343 84.7048 29.7013 84.9841 29.4619V27.5201H83.2418V25.9108H86.7397V30.353C86.0747 31.0047 84.9176 31.5367 83.5477 31.5367Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M73.0943 26.2176L72.8017 27.2949H75.1425L74.8499 26.2176C74.544 25.1935 74.278 24.063 73.9987 22.999H73.9322C73.6795 24.0763 73.3869 25.1935 73.0943 26.2176ZM69.6895 31.3514L72.8416 21.4961H75.1558L78.3212 31.3514H76.2597L75.5681 28.8244H72.3761L71.6845 31.3514H69.6895Z"
                  fill="black"
                  fillOpacity="0.9"
                />
                <path
                  d="M59.6016 31.3514V21.4961H61.7828L63.4586 26.1112C63.6714 26.723 63.8576 27.3747 64.0571 27.9998H64.1236C64.3364 27.3747 64.5093 26.723 64.7221 26.1112L66.358 21.4961H68.5392V31.3514H66.7437V27.2417C66.7437 26.3107 66.89 24.9408 66.9964 24.0098H66.9299L66.1319 26.324L64.6157 30.4603H63.4852L61.9557 26.324L61.171 24.0098H61.1178C61.2109 24.9408 61.3705 26.3107 61.3705 27.2417V31.3514H59.6016Z"
                  fill="black"
                  fillOpacity="0.9"
                />
              </svg>
            ),
          },
          {
            defaultImg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="238"
                height="30"
                viewBox="0 0 238 30"
                fill="none"
              >
                <ellipse cx="234.961" cy="27.0962" rx="2.90076" ry="2.9038" fill="#414756" />
                <path
                  d="M0 0.258789H7.19567V28.4168C3.22161 28.4168 0 25.1952 0 21.2212V0.258789Z"
                  fill="#414756"
                />
                <path
                  d="M23.5533 21.2119C23.5533 25.1901 20.3284 28.4151 16.3501 28.4151L8.50785 28.4151L8.50785 21.2119L23.5533 21.2119Z"
                  fill="#414756"
                />
                <path
                  d="M221.614 3.2836C221.614 4.70252 221.585 5.93606 221.528 6.98421C221.471 8.03236 221.325 9.16963 221.09 10.396H224.456V13.4228H213.857V15.3159H221.731V22.6102H206.068V24.2573H222.693V27.2413H202.254V19.8508H217.896V18.2571H202.211V15.3159H210.021V13.4228H199.487V10.396H217.201C217.337 9.72579 217.454 8.98068 217.554 8.1607L202.19 8.78103L201.752 5.80771L217.735 5.49755C217.75 5.01269 217.757 4.65618 217.757 4.42801L217.767 3.89324H202.51V0.919922H221.614V3.2836Z"
                  fill="#414756"
                />
                <path
                  d="M187.158 3.63685C187.151 5.00586 187.54 6.34991 188.323 7.66901C189.107 8.98098 190.239 10.1218 191.721 11.0915C193.202 12.0541 194.944 12.7065 196.945 13.0488L195.246 16.2574C193.672 15.9437 192.226 15.4481 190.909 14.7708C189.598 14.0934 188.455 13.2698 187.479 12.3001C186.503 11.3304 185.716 10.2537 185.118 9.07011C184.512 10.2466 183.718 11.3197 182.735 12.2894C181.752 13.2591 180.602 14.0862 179.284 14.7708C177.974 15.4481 176.538 15.9437 174.978 16.2574L173.322 13.0488C175.288 12.6994 177.005 12.0399 178.472 11.0701C179.947 10.0933 181.076 8.94889 181.859 7.63693C182.643 6.32496 183.038 4.9916 183.045 3.63685V1.6582H187.158V3.63685ZM172.692 20.9955H197.843V24.172H172.692V20.9955Z"
                  fill="#414756"
                />
                <path
                  d="M160.802 1.65797C160.795 4.24626 160.349 6.52081 159.466 8.48163C158.59 10.4425 157.198 12.1323 155.289 13.5512C153.387 14.963 150.908 16.1074 147.853 16.9845L146.453 13.9256C148.376 13.4051 150.004 12.7847 151.336 12.0646C152.668 11.3444 153.743 10.5245 154.562 9.60465L146.944 10.1501L146.399 7.1554L156.197 6.82385C156.425 6.19638 156.585 5.49049 156.678 4.70616H147.788V1.65797H160.802ZM150.118 22.1183C150.118 20.9845 150.502 20.0113 151.271 19.1984C152.041 18.3784 153.138 17.7545 154.562 17.3267C155.994 16.8989 157.689 16.685 159.648 16.685C161.557 16.685 163.22 16.8989 164.637 17.3267C166.055 17.7545 167.148 18.3784 167.917 19.1984C168.687 20.0113 169.071 20.9845 169.071 22.1183C169.071 23.252 168.687 24.2217 167.917 25.0274C167.148 25.8402 166.055 26.457 164.637 26.8777C163.22 27.2984 161.557 27.5087 159.648 27.5087C157.689 27.5087 155.994 27.2984 154.562 26.8777C153.131 26.457 152.03 25.8402 151.261 25.0274C150.499 24.2217 150.118 23.252 150.118 22.1183ZM154.017 22.1183C154.01 22.9168 154.484 23.5265 155.438 23.9472C156.393 24.3607 157.796 24.5675 159.648 24.5675C161.45 24.5675 162.828 24.3572 163.783 23.9365C164.737 23.5158 165.218 22.9097 165.225 22.1183C165.218 21.3197 164.737 20.7172 163.783 20.3107C162.835 19.9043 161.457 19.7011 159.648 19.7011C157.782 19.7011 156.375 19.9043 155.428 20.3107C154.48 20.7172 154.01 21.3197 154.017 22.1183ZM165.097 0.0429688H168.964V16.2679H165.097V0.0429688Z"
                  fill="#414756"
                />
                <path
                  d="M132.468 7.28356H119.156V4.21398H123.964V0.780763H127.757V4.21398H132.468V7.28356ZM119.818 15.0377C119.818 13.7899 120.078 12.6705 120.598 11.6794C121.118 10.6883 121.838 9.91463 122.757 9.35847C123.683 8.80231 124.722 8.52423 125.876 8.52423C127.037 8.52423 128.074 8.80231 128.985 9.35847C129.904 9.91463 130.62 10.6883 131.133 11.6794C131.653 12.6633 131.913 13.7828 131.913 15.0377C131.913 16.3069 131.653 17.4406 131.133 18.4389C130.62 19.4371 129.904 20.2179 128.985 20.7811C128.074 21.3373 127.037 21.6154 125.876 21.6154C124.722 21.6154 123.683 21.3373 122.757 20.7811C121.838 20.2179 121.118 19.4371 120.598 18.4389C120.078 17.4406 119.818 16.3069 119.818 15.0377ZM123.205 15.0377C123.205 15.7294 123.316 16.3247 123.537 16.8238C123.757 17.323 124.067 17.7044 124.466 17.9683C124.872 18.2249 125.342 18.3497 125.876 18.3426C126.396 18.3497 126.856 18.2249 127.255 17.9683C127.653 17.7044 127.963 17.323 128.184 16.8238C128.412 16.3247 128.526 15.7294 128.526 15.0377C128.526 14.3603 128.412 13.7721 128.184 13.273C127.963 12.7739 127.65 12.3924 127.244 12.1286C126.845 11.8576 126.389 11.7221 125.876 11.7221C125.349 11.7221 124.883 11.8576 124.477 12.1286C124.071 12.3924 123.757 12.7739 123.537 13.273C123.316 13.7721 123.205 14.3603 123.205 15.0377ZM133.483 0.545465H137.095V11.2195H139.328V0H142.992V27.5513H139.328V14.2997H137.095V26.3641H133.483V0.545465Z"
                  fill="#414756"
                />
                <path
                  d="M117.619 24.3436H92.4681V21.1456H117.619V24.3436ZM94.434 2.42871H115.525V5.53037H111.646V13.8621H115.396V16.9103H94.5516V13.8621H98.2376V5.53037H94.434V2.42871ZM107.843 13.8621V5.53037H102.084V13.8621H107.843Z"
                  fill="#414756"
                />
                <path
                  d="M88.7404 27.6155H84.8407V0H88.7404V27.6155ZM66.528 11.7435C66.528 9.78271 66.8379 8.06432 67.4575 6.58836C68.0772 5.10527 68.9391 3.96086 70.0431 3.15514C71.1471 2.34942 72.4008 1.94656 73.8039 1.94656C75.1858 1.94656 76.4251 2.34942 77.522 3.15514C78.6189 3.96086 79.4808 5.10527 80.1076 6.58836C80.7344 8.06432 81.0478 9.78271 81.0478 11.7435C81.0478 13.7186 80.7344 15.4513 80.1076 16.9415C79.4808 18.4317 78.6189 19.5797 77.522 20.3854C76.4251 21.184 75.1858 21.5833 73.8039 21.5833C72.4008 21.5833 71.1471 21.184 70.0431 20.3854C68.9391 19.5797 68.0772 18.4353 67.4575 16.9522C66.8379 15.462 66.528 13.7257 66.528 11.7435ZM70.2568 11.7435C70.2497 13.0912 70.3921 14.2427 70.6842 15.1981C70.9762 16.1465 71.3893 16.8666 71.9235 17.3586C72.4577 17.8435 73.0845 18.0859 73.8039 18.0859C74.5091 18.0859 75.1252 17.8435 75.6523 17.3586C76.1794 16.8666 76.589 16.1465 76.881 15.1981C77.173 14.2427 77.319 13.0912 77.319 11.7435C77.319 10.4102 77.173 9.26934 76.881 8.32101C76.589 7.37269 76.1794 6.6561 75.6523 6.17124C75.1252 5.67925 74.5091 5.43326 73.8039 5.43326C73.0845 5.43326 72.4577 5.67925 71.9235 6.17124C71.3893 6.6561 70.9762 7.37269 70.6842 8.32101C70.3921 9.26934 70.2497 10.4102 70.2568 11.7435Z"
                  fill="#414756"
                />
                <path
                  d="M60.6743 10.4066H64.7343V13.6045H60.6743V27.6155H56.86V0H60.6743V10.4066ZM40.0966 2.3209H52.8107V12.9842H43.9642V17.9896C46.1011 17.9754 48.0242 17.9076 49.7337 17.7864C51.4503 17.6652 53.1811 17.4584 54.9262 17.1661L55.2681 20.2464C53.288 20.61 51.3149 20.8596 49.3491 20.995C47.3832 21.1234 45.0789 21.1911 42.4364 21.1983H40.1286V9.93602H48.9644V5.43326H40.0966V2.3209Z"
                  fill="#414756"
                />
              </svg>
            ),
            defaultImgMob: (
              <svg
                width="191"
                height="24"
                viewBox="0 0 191 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse cx="187.969" cy="21.6766" rx="2.32062" ry="2.32305" fill="#414756" />
                <path
                  d="M0 0.207031H5.75657V22.7336V22.7336C2.5773 22.7336 0 20.1563 0 16.977V0.207031Z"
                  fill="#414756"
                />
                <path
                  d="M18.8438 16.9707V16.9707C18.8437 20.1533 16.2637 22.7333 13.0811 22.7333L6.80728 22.7333L6.80728 16.9707L18.8438 16.9707Z"
                  fill="#414756"
                />
                <path
                  d="M177.297 2.62728C177.297 3.76243 177.275 4.74926 177.229 5.58779C177.183 6.42631 177.067 7.33613 176.879 8.31726H179.571V10.7387H171.092V12.2532H177.391V18.0886H164.861V19.4063H178.161V21.7935H161.809V15.8811H174.323V14.6062H161.775V12.2532H168.023V10.7387H159.596V8.31726H173.767C173.876 7.78107 173.97 7.18497 174.049 6.52899L161.758 7.02525L161.408 4.64659L174.195 4.39845C174.206 4.01056 174.212 3.72535 174.212 3.54281L174.22 3.115H162.015V0.736328H177.297V2.62728Z"
                  fill="#414756"
                />
                <path
                  d="M149.733 2.9091C149.728 4.00431 150.038 5.07956 150.665 6.13485C151.292 7.18443 152.198 8.09711 153.383 8.87288C154.568 9.64295 155.962 10.1649 157.563 10.4387L156.204 13.0056C154.944 12.7546 153.788 12.3582 152.733 11.8163C151.685 11.2744 150.77 10.6155 149.99 9.83975C149.209 9.06397 148.579 8.20263 148.101 7.25573C147.616 8.19693 146.981 9.05542 146.195 9.83119C145.408 10.607 144.488 11.2687 143.434 11.8163C142.385 12.3582 141.237 12.7546 139.989 13.0056L138.664 10.4387C140.237 10.1592 141.61 9.63155 142.784 8.85577C143.964 8.07429 144.867 7.15876 145.494 6.10918C146.121 5.0596 146.437 3.99291 146.443 2.9091V1.32617H149.733V2.9091ZM138.16 16.7961H158.281V19.3373H138.16V16.7961Z"
                  fill="#414756"
                />
                <path
                  d="M128.647 1.32521C128.641 3.39585 128.285 5.21551 127.578 6.78417C126.878 8.35284 125.764 9.70474 124.236 10.8399C122.715 11.9693 120.732 12.8849 118.287 13.5865L117.168 11.1394C118.706 10.723 120.008 10.2267 121.074 9.65055C122.139 9.07443 123 8.41844 123.655 7.68259L117.561 8.11897L117.125 5.72318L124.963 5.45794C125.145 4.95596 125.274 4.39124 125.348 3.76378H118.236V1.32521H128.647ZM120.099 17.6935C120.099 16.7866 120.407 16.0079 121.023 15.3576C121.638 14.7017 122.516 14.2025 123.655 13.8603C124.801 13.518 126.157 13.3469 127.724 13.3469C129.251 13.3469 130.581 13.518 131.715 13.8603C132.849 14.2025 133.724 14.7017 134.339 15.3576C134.955 16.0079 135.263 16.7866 135.263 17.6935C135.263 18.6005 134.955 19.3763 134.339 20.0209C133.724 20.6711 132.849 21.1646 131.715 21.5011C130.581 21.8377 129.251 22.0059 127.724 22.0059C126.157 22.0059 124.801 21.8377 123.655 21.5011C122.51 21.1646 121.629 20.6711 121.014 20.0209C120.404 19.3763 120.099 18.6005 120.099 17.6935ZM123.219 17.6935C123.214 18.3324 123.593 18.8201 124.356 19.1567C125.12 19.4875 126.242 19.6529 127.724 19.6529C129.165 19.6529 130.268 19.4847 131.032 19.1481C131.795 18.8116 132.18 18.3267 132.186 17.6935C132.18 17.0547 131.795 16.5727 131.032 16.2475C130.274 15.9224 129.171 15.7598 127.724 15.7598C126.231 15.7598 125.105 15.9224 124.348 16.2475C123.59 16.5727 123.214 17.0547 123.219 17.6935ZM132.083 0.0332031H135.177V13.0132H132.083V0.0332031Z"
                  fill="#414756"
                />
                <path
                  d="M105.98 5.82688H95.3301V3.37121H99.1764V0.624614H102.211V3.37121H105.98V5.82688ZM95.86 12.0302C95.86 11.032 96.068 10.1364 96.484 9.34355C96.9 8.55066 97.4755 7.93175 98.2106 7.48682C98.9513 7.04189 99.7833 6.81942 100.706 6.81942C101.635 6.81942 102.464 7.04189 103.194 7.48682C103.929 7.93175 104.501 8.55066 104.912 9.34355C105.328 10.1307 105.536 11.0263 105.536 12.0302C105.536 13.0456 105.328 13.9526 104.912 14.7512C104.501 15.5498 103.929 16.1744 103.194 16.625C102.464 17.0699 101.635 17.2924 100.706 17.2924C99.7833 17.2924 98.9513 17.0699 98.2106 16.625C97.4755 16.1744 96.9 15.5498 96.484 14.7512C96.068 13.9526 95.86 13.0456 95.86 12.0302ZM98.5695 12.0302C98.5695 12.5836 98.6579 13.0599 98.8345 13.4592C99.0112 13.8585 99.259 14.1636 99.5781 14.3747C99.9029 14.58 100.279 14.6799 100.706 14.6742C101.122 14.6799 101.49 14.58 101.809 14.3747C102.128 14.1636 102.376 13.8585 102.553 13.4592C102.735 13.0599 102.826 12.5836 102.826 12.0302C102.826 11.4883 102.735 11.0177 102.553 10.6184C102.376 10.2191 102.125 9.91397 101.8 9.70291C101.481 9.48615 101.117 9.37777 100.706 9.37777C100.285 9.37777 99.9115 9.48615 99.5867 9.70291C99.2619 9.91397 99.0112 10.2191 98.8345 10.6184C98.6579 11.0177 98.5695 11.4883 98.5695 12.0302ZM106.792 0.436374H109.681V8.97562H111.468V0H114.399V22.0412H111.468V11.4399H109.681V21.0914H106.792V0.436374Z"
                  fill="#414756"
                />
                <path
                  d="M94.103 19.4753H73.9824V16.917H94.103V19.4753ZM75.5551 1.94336H92.4277V4.4247H89.325V11.0901H92.3251V13.5287H75.6492V11.0901H78.598V4.4247H75.5551V1.94336ZM86.2821 11.0901V4.4247H81.6751V11.0901H86.2821Z"
                  fill="#414756"
                />
                <path
                  d="M70.9986 22.0925H67.8788V0H70.9986V22.0925ZM53.2285 9.39488C53.2285 7.82622 53.4764 6.4515 53.9721 5.27072C54.4679 4.08424 55.1574 3.16871 56.0406 2.52413C56.9238 1.87955 57.9267 1.55726 59.0493 1.55726C60.1548 1.55726 61.1463 1.87955 62.0238 2.52413C62.9013 3.16871 63.5908 4.08424 64.0923 5.27072C64.5937 6.4515 64.8444 7.82622 64.8444 9.39488C64.8444 10.975 64.5937 12.3611 64.0923 13.5533C63.5908 14.7455 62.9013 15.6638 62.0238 16.3084C61.1463 16.9473 60.1548 17.2667 59.0493 17.2667C57.9267 17.2667 56.9238 16.9473 56.0406 16.3084C55.1574 15.6638 54.4679 14.7483 53.9721 13.5618C53.4764 12.3696 53.2285 10.9807 53.2285 9.39488ZM56.2116 9.39488C56.2059 10.473 56.3198 11.3942 56.5535 12.1586C56.7871 12.9173 57.1176 13.4934 57.545 13.887C57.9723 14.2749 58.4738 14.4688 59.0493 14.4688C59.6134 14.4688 60.1063 14.2749 60.528 13.887C60.9497 13.4934 61.2773 12.9173 61.5109 12.1586C61.7446 11.3942 61.8614 10.473 61.8614 9.39488C61.8614 8.32819 61.7446 7.41551 61.5109 6.65685C61.2773 5.89818 60.9497 5.32491 60.528 4.93702C60.1063 4.54343 59.6134 4.34663 59.0493 4.34663C58.4738 4.34663 57.9723 4.54343 57.545 4.93702C57.1176 5.32491 56.7871 5.89818 56.5535 6.65685C56.3198 7.41551 56.2059 8.32819 56.2116 9.39488Z"
                  fill="#414756"
                />
                <path
                  d="M48.5463 8.32534H51.7943V10.8837H48.5463V22.0925H45.4949V0H48.5463V8.32534ZM32.084 1.85673H42.2554V10.3874H35.1781V14.3918C36.8876 14.3804 38.4262 14.3262 39.7937 14.2292C41.167 14.1323 42.5517 13.9668 43.9478 13.733L44.2213 16.1972C42.6372 16.4881 41.0588 16.6878 39.486 16.7961C37.9133 16.8988 36.0699 16.953 33.9559 16.9587H32.1096V7.94886H39.1783V4.34663H32.084V1.85673Z"
                  fill="#414756"
                />
              </svg>
            ),
            hoverImg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="238"
                height="30"
                viewBox="0 0 238 30"
                fill="none"
              >
                <ellipse cx="234.961" cy="27.0962" rx="2.90076" ry="2.9038" fill="#FF3D00" />
                <path
                  d="M0 0.258789H7.19567V28.4168C3.22161 28.4168 0 25.1952 0 21.2212V0.258789Z"
                  fill="black"
                />
                <path
                  d="M23.5533 21.2119C23.5533 25.1901 20.3284 28.4151 16.3501 28.4151L8.50785 28.4151L8.50785 21.2119L23.5533 21.2119Z"
                  fill="#FF3D00"
                />
                <path
                  d="M221.614 3.2836C221.614 4.70252 221.585 5.93606 221.528 6.98421C221.471 8.03236 221.325 9.16963 221.09 10.396H224.456V13.4228H213.857V15.3159H221.731V22.6102H206.068V24.2573H222.693V27.2413H202.254V19.8508H217.896V18.2571H202.211V15.3159H210.021V13.4228H199.487V10.396H217.201C217.337 9.72579 217.454 8.98068 217.554 8.1607L202.19 8.78103L201.752 5.80771L217.735 5.49755C217.75 5.01269 217.757 4.65618 217.757 4.42801L217.767 3.89324H202.51V0.919922H221.614V3.2836Z"
                  fill="black"
                />
                <path
                  d="M187.158 3.63685C187.151 5.00586 187.54 6.34991 188.323 7.66901C189.107 8.98098 190.239 10.1218 191.721 11.0915C193.202 12.0541 194.944 12.7065 196.945 13.0488L195.246 16.2574C193.672 15.9437 192.226 15.4481 190.909 14.7708C189.598 14.0934 188.455 13.2698 187.479 12.3001C186.503 11.3304 185.716 10.2537 185.118 9.07011C184.512 10.2466 183.718 11.3197 182.735 12.2894C181.752 13.2591 180.602 14.0862 179.284 14.7708C177.974 15.4481 176.538 15.9437 174.978 16.2574L173.322 13.0488C175.288 12.6994 177.005 12.0399 178.472 11.0701C179.947 10.0933 181.076 8.94889 181.859 7.63693C182.643 6.32496 183.038 4.9916 183.045 3.63685V1.6582H187.158V3.63685ZM172.692 20.9955H197.843V24.172H172.692V20.9955Z"
                  fill="black"
                />
                <path
                  d="M160.802 1.65797C160.795 4.24626 160.349 6.52081 159.466 8.48163C158.59 10.4425 157.198 12.1323 155.289 13.5512C153.387 14.963 150.908 16.1074 147.853 16.9845L146.453 13.9256C148.376 13.4051 150.004 12.7847 151.336 12.0646C152.668 11.3444 153.743 10.5245 154.562 9.60465L146.944 10.1501L146.399 7.1554L156.197 6.82385C156.425 6.19638 156.585 5.49049 156.678 4.70616H147.788V1.65797H160.802ZM150.118 22.1183C150.118 20.9845 150.502 20.0113 151.271 19.1984C152.041 18.3784 153.138 17.7545 154.562 17.3267C155.994 16.8989 157.689 16.685 159.648 16.685C161.557 16.685 163.22 16.8989 164.637 17.3267C166.055 17.7545 167.148 18.3784 167.917 19.1984C168.687 20.0113 169.071 20.9845 169.071 22.1183C169.071 23.252 168.687 24.2217 167.917 25.0274C167.148 25.8402 166.055 26.457 164.637 26.8777C163.22 27.2984 161.557 27.5087 159.648 27.5087C157.689 27.5087 155.994 27.2984 154.562 26.8777C153.131 26.457 152.03 25.8402 151.261 25.0274C150.499 24.2217 150.118 23.252 150.118 22.1183ZM154.017 22.1183C154.01 22.9168 154.484 23.5265 155.438 23.9472C156.393 24.3607 157.796 24.5675 159.648 24.5675C161.45 24.5675 162.828 24.3572 163.783 23.9365C164.737 23.5158 165.218 22.9097 165.225 22.1183C165.218 21.3197 164.737 20.7172 163.783 20.3107C162.835 19.9043 161.457 19.7011 159.648 19.7011C157.782 19.7011 156.375 19.9043 155.428 20.3107C154.48 20.7172 154.01 21.3197 154.017 22.1183ZM165.097 0.0429688H168.964V16.2679H165.097V0.0429688Z"
                  fill="black"
                />
                <path
                  d="M132.468 7.28356H119.156V4.21398H123.964V0.780763H127.757V4.21398H132.468V7.28356ZM119.818 15.0377C119.818 13.7899 120.078 12.6705 120.598 11.6794C121.118 10.6883 121.838 9.91463 122.757 9.35847C123.683 8.80231 124.722 8.52423 125.876 8.52423C127.037 8.52423 128.074 8.80231 128.985 9.35847C129.904 9.91463 130.62 10.6883 131.133 11.6794C131.653 12.6633 131.913 13.7828 131.913 15.0377C131.913 16.3069 131.653 17.4406 131.133 18.4389C130.62 19.4371 129.904 20.2179 128.985 20.7811C128.074 21.3373 127.037 21.6154 125.876 21.6154C124.722 21.6154 123.683 21.3373 122.757 20.7811C121.838 20.2179 121.118 19.4371 120.598 18.4389C120.078 17.4406 119.818 16.3069 119.818 15.0377ZM123.205 15.0377C123.205 15.7294 123.316 16.3247 123.537 16.8238C123.757 17.323 124.067 17.7044 124.466 17.9683C124.872 18.2249 125.342 18.3497 125.876 18.3426C126.396 18.3497 126.856 18.2249 127.255 17.9683C127.653 17.7044 127.963 17.323 128.184 16.8238C128.412 16.3247 128.526 15.7294 128.526 15.0377C128.526 14.3603 128.412 13.7721 128.184 13.273C127.963 12.7739 127.65 12.3924 127.244 12.1286C126.845 11.8576 126.389 11.7221 125.876 11.7221C125.349 11.7221 124.883 11.8576 124.477 12.1286C124.071 12.3924 123.757 12.7739 123.537 13.273C123.316 13.7721 123.205 14.3603 123.205 15.0377ZM133.483 0.545465H137.095V11.2195H139.328V0H142.992V27.5513H139.328V14.2997H137.095V26.3641H133.483V0.545465Z"
                  fill="black"
                />
                <path
                  d="M117.619 24.3436H92.4681V21.1456H117.619V24.3436ZM94.434 2.42871H115.525V5.53037H111.646V13.8621H115.396V16.9103H94.5516V13.8621H98.2376V5.53037H94.434V2.42871ZM107.843 13.8621V5.53037H102.084V13.8621H107.843Z"
                  fill="black"
                />
                <path
                  d="M88.7404 27.6155H84.8407V0H88.7404V27.6155ZM66.528 11.7435C66.528 9.78271 66.8379 8.06432 67.4575 6.58836C68.0772 5.10527 68.9391 3.96086 70.0431 3.15514C71.1471 2.34942 72.4008 1.94656 73.8039 1.94656C75.1858 1.94656 76.4251 2.34942 77.522 3.15514C78.6189 3.96086 79.4808 5.10527 80.1076 6.58836C80.7344 8.06432 81.0478 9.78271 81.0478 11.7435C81.0478 13.7186 80.7344 15.4513 80.1076 16.9415C79.4808 18.4317 78.6189 19.5797 77.522 20.3854C76.4251 21.184 75.1858 21.5833 73.8039 21.5833C72.4008 21.5833 71.1471 21.184 70.0431 20.3854C68.9391 19.5797 68.0772 18.4353 67.4575 16.9522C66.8379 15.462 66.528 13.7257 66.528 11.7435ZM70.2568 11.7435C70.2497 13.0912 70.3921 14.2427 70.6842 15.1981C70.9762 16.1465 71.3893 16.8666 71.9235 17.3586C72.4577 17.8435 73.0845 18.0859 73.8039 18.0859C74.5091 18.0859 75.1252 17.8435 75.6523 17.3586C76.1794 16.8666 76.589 16.1465 76.881 15.1981C77.173 14.2427 77.319 13.0912 77.319 11.7435C77.319 10.4102 77.173 9.26934 76.881 8.32101C76.589 7.37269 76.1794 6.6561 75.6523 6.17124C75.1252 5.67925 74.5091 5.43326 73.8039 5.43326C73.0845 5.43326 72.4577 5.67925 71.9235 6.17124C71.3893 6.6561 70.9762 7.37269 70.6842 8.32101C70.3921 9.26934 70.2497 10.4102 70.2568 11.7435Z"
                  fill="black"
                />
                <path
                  d="M60.6743 10.4066H64.7343V13.6045H60.6743V27.6155H56.86V0H60.6743V10.4066ZM40.0966 2.3209H52.8107V12.9842H43.9642V17.9896C46.1011 17.9754 48.0242 17.9076 49.7337 17.7864C51.4503 17.6652 53.1811 17.4584 54.9262 17.1661L55.2681 20.2464C53.288 20.61 51.3149 20.8596 49.3491 20.995C47.3832 21.1234 45.0789 21.1911 42.4364 21.1983H40.1286V9.93602H48.9644V5.43326H40.0966V2.3209Z"
                  fill="black"
                />
              </svg>
            ),
            hoverImgMob: (
              <svg
                width="191"
                height="24"
                viewBox="0 0 191 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse cx="187.969" cy="21.6766" rx="2.32062" ry="2.32305" fill="#FF3D00" />
                <path
                  d="M0 0.207031H5.75657V22.7336C2.5773 22.7336 0 20.1563 0 16.977V0.207031Z"
                  fill="black"
                />
                <path
                  d="M18.8438 16.9707C18.8437 20.1533 16.2637 22.7333 13.0811 22.7333L6.80728 22.7333L6.80728 16.9707L18.8438 16.9707Z"
                  fill="#FF3D00"
                />
                <path
                  d="M177.297 2.62728C177.297 3.76243 177.275 4.74926 177.229 5.58779C177.183 6.42631 177.067 7.33613 176.879 8.31726H179.571V10.7387H171.092V12.2532H177.391V18.0886H164.861V19.4063H178.161V21.7935H161.809V15.8811H174.323V14.6062H161.775V12.2532H168.023V10.7387H159.596V8.31726H173.767C173.876 7.78107 173.97 7.18497 174.049 6.52899L161.758 7.02525L161.408 4.64659L174.195 4.39845C174.206 4.01056 174.212 3.72535 174.212 3.54281L174.22 3.115H162.015V0.736328H177.297V2.62728Z"
                  fill="black"
                />
                <path
                  d="M149.733 2.9091C149.728 4.00431 150.038 5.07956 150.665 6.13485C151.292 7.18443 152.198 8.09711 153.383 8.87288C154.568 9.64295 155.962 10.1649 157.563 10.4387L156.204 13.0056C154.944 12.7546 153.788 12.3582 152.733 11.8163C151.685 11.2744 150.77 10.6155 149.99 9.83975C149.209 9.06397 148.579 8.20263 148.101 7.25573C147.616 8.19693 146.981 9.05542 146.195 9.83119C145.408 10.607 144.488 11.2687 143.434 11.8163C142.385 12.3582 141.237 12.7546 139.989 13.0056L138.664 10.4387C140.237 10.1592 141.61 9.63155 142.784 8.85577C143.964 8.07429 144.867 7.15876 145.494 6.10918C146.121 5.0596 146.437 3.99291 146.443 2.9091V1.32617H149.733V2.9091ZM138.16 16.7961H158.281V19.3373H138.16V16.7961Z"
                  fill="black"
                />
                <path
                  d="M128.647 1.32521C128.641 3.39585 128.285 5.21551 127.578 6.78417C126.878 8.35284 125.764 9.70474 124.236 10.8399C122.715 11.9693 120.732 12.8849 118.287 13.5865L117.168 11.1394C118.706 10.723 120.008 10.2267 121.074 9.65055C122.139 9.07443 123 8.41844 123.655 7.68259L117.561 8.11897L117.125 5.72318L124.963 5.45794C125.145 4.95596 125.274 4.39124 125.348 3.76378H118.236V1.32521H128.647ZM120.099 17.6935C120.099 16.7866 120.407 16.0079 121.023 15.3576C121.638 14.7017 122.516 14.2025 123.655 13.8603C124.801 13.518 126.157 13.3469 127.724 13.3469C129.251 13.3469 130.581 13.518 131.715 13.8603C132.849 14.2025 133.724 14.7017 134.339 15.3576C134.955 16.0079 135.263 16.7866 135.263 17.6935C135.263 18.6005 134.955 19.3763 134.339 20.0209C133.724 20.6711 132.849 21.1646 131.715 21.5011C130.581 21.8377 129.251 22.0059 127.724 22.0059C126.157 22.0059 124.801 21.8377 123.655 21.5011C122.51 21.1646 121.629 20.6711 121.014 20.0209C120.404 19.3763 120.099 18.6005 120.099 17.6935ZM123.219 17.6935C123.214 18.3324 123.593 18.8201 124.356 19.1567C125.12 19.4875 126.242 19.6529 127.724 19.6529C129.165 19.6529 130.268 19.4847 131.032 19.1481C131.795 18.8116 132.18 18.3267 132.186 17.6935C132.18 17.0547 131.795 16.5727 131.032 16.2475C130.274 15.9224 129.171 15.7598 127.724 15.7598C126.231 15.7598 125.105 15.9224 124.348 16.2475C123.59 16.5727 123.214 17.0547 123.219 17.6935ZM132.083 0.0332031H135.177V13.0132H132.083V0.0332031Z"
                  fill="black"
                />
                <path
                  d="M105.98 5.82688H95.3301V3.37121H99.1764V0.624614H102.211V3.37121H105.98V5.82688ZM95.86 12.0302C95.86 11.032 96.068 10.1364 96.484 9.34355C96.9 8.55066 97.4755 7.93175 98.2106 7.48682C98.9513 7.04189 99.7833 6.81942 100.706 6.81942C101.635 6.81942 102.464 7.04189 103.194 7.48682C103.929 7.93175 104.501 8.55066 104.912 9.34355C105.328 10.1307 105.536 11.0263 105.536 12.0302C105.536 13.0456 105.328 13.9526 104.912 14.7512C104.501 15.5498 103.929 16.1744 103.194 16.625C102.464 17.0699 101.635 17.2924 100.706 17.2924C99.7833 17.2924 98.9513 17.0699 98.2106 16.625C97.4755 16.1744 96.9 15.5498 96.484 14.7512C96.068 13.9526 95.86 13.0456 95.86 12.0302ZM98.5695 12.0302C98.5695 12.5836 98.6579 13.0599 98.8345 13.4592C99.0112 13.8585 99.259 14.1636 99.5781 14.3747C99.9029 14.58 100.279 14.6799 100.706 14.6742C101.122 14.6799 101.49 14.58 101.809 14.3747C102.128 14.1636 102.376 13.8585 102.553 13.4592C102.735 13.0599 102.826 12.5836 102.826 12.0302C102.826 11.4883 102.735 11.0177 102.553 10.6184C102.376 10.2191 102.125 9.91397 101.8 9.70291C101.481 9.48615 101.117 9.37777 100.706 9.37777C100.285 9.37777 99.9115 9.48615 99.5867 9.70291C99.2619 9.91397 99.0112 10.2191 98.8345 10.6184C98.6579 11.0177 98.5695 11.4883 98.5695 12.0302ZM106.792 0.436374H109.681V8.97562H111.468V0H114.399V22.0412H111.468V11.4399H109.681V21.0914H106.792V0.436374Z"
                  fill="black"
                />
                <path
                  d="M94.103 19.4753H73.9824V16.917H94.103V19.4753ZM75.5551 1.94336H92.4277V4.4247H89.325V11.0901H92.3251V13.5287H75.6492V11.0901H78.598V4.4247H75.5551V1.94336ZM86.2821 11.0901V4.4247H81.6751V11.0901H86.2821Z"
                  fill="black"
                />
                <path
                  d="M70.9986 22.0925H67.8788V0H70.9986V22.0925ZM53.2285 9.39488C53.2285 7.82622 53.4764 6.4515 53.9721 5.27072C54.4679 4.08424 55.1574 3.16871 56.0406 2.52413C56.9238 1.87955 57.9267 1.55726 59.0493 1.55726C60.1548 1.55726 61.1463 1.87955 62.0238 2.52413C62.9013 3.16871 63.5908 4.08424 64.0923 5.27072C64.5937 6.4515 64.8444 7.82622 64.8444 9.39488C64.8444 10.975 64.5937 12.3611 64.0923 13.5533C63.5908 14.7455 62.9013 15.6638 62.0238 16.3084C61.1463 16.9473 60.1548 17.2667 59.0493 17.2667C57.9267 17.2667 56.9238 16.9473 56.0406 16.3084C55.1574 15.6638 54.4679 14.7483 53.9721 13.5618C53.4764 12.3696 53.2285 10.9807 53.2285 9.39488ZM56.2116 9.39488C56.2059 10.473 56.3198 11.3942 56.5535 12.1586C56.7871 12.9173 57.1176 13.4934 57.545 13.887C57.9723 14.2749 58.4738 14.4688 59.0493 14.4688C59.6134 14.4688 60.1063 14.2749 60.528 13.887C60.9497 13.4934 61.2773 12.9173 61.5109 12.1586C61.7446 11.3942 61.8614 10.473 61.8614 9.39488C61.8614 8.32819 61.7446 7.41551 61.5109 6.65685C61.2773 5.89818 60.9497 5.32491 60.528 4.93702C60.1063 4.54343 59.6134 4.34663 59.0493 4.34663C58.4738 4.34663 57.9723 4.54343 57.545 4.93702C57.1176 5.32491 56.7871 5.89818 56.5535 6.65685C56.3198 7.41551 56.2059 8.32819 56.2116 9.39488Z"
                  fill="black"
                />
                <path
                  d="M48.5463 8.32534H51.7943V10.8837H48.5463V22.0925H45.4949V0H48.5463V8.32534ZM32.084 1.85673H42.2554V10.3874H35.1781V14.3918C36.8876 14.3804 38.4262 14.3262 39.7937 14.2292C41.167 14.1323 42.5517 13.9668 43.9478 13.733L44.2213 16.1972C42.6372 16.4881 41.0588 16.6878 39.486 16.7961C37.9133 16.8988 36.0699 16.953 33.9559 16.9587H32.1096V7.94886H39.1783V4.34663H32.084V1.85673Z"
                  fill="black"
                />
              </svg>
            ),
          },
          {
            defaultImg: (
              <svg
                width="190"
                height="26"
                viewBox="0 0 190 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.04295 21.6875C3.84487 21.6875 0 18.0361 0 12.6553C0 7.27437 3.84487 3.62305 9.04295 3.62305C14.241 3.62305 18.0644 7.27437 18.0644 12.6553C18.0644 18.0361 14.241 21.6875 9.04295 21.6875ZM4.59665 12.6553C4.59665 15.4525 6.40095 17.3956 9.04295 17.3956C11.685 17.3956 13.4678 15.4525 13.4678 12.6553C13.4678 9.85805 11.685 7.91495 9.04295 7.91495C6.40095 7.91495 4.59665 9.85805 4.59665 12.6553Z"
                  fill="#414756"
                />
                <path
                  d="M19.2949 17.7011L26.4053 8.20928H19.2949V4.03516H32.3511V7.5803L25.5472 17.0721H33.0867V21.2748H19.2949V17.7011Z"
                  fill="#414756"
                />
                <rect x="34.9902" y="17.1582" width="4.1174" height="4.1174" fill="#984BFF" />
                <path
                  d="M187.491 0.75V3.0077C187.491 4.93452 187.491 6.9392 187.053 9.34286H190.002V12.4277H180.494V13.8582H187.578V20.8162H173.487V21.9645H188.454V25.0007H169.566V18.0136H183.627V16.8555H169.536V13.8582H176.533V12.4277H167.104V9.34286H183.053C183.17 8.74924 183.248 8.19455 183.316 7.65932L169.546 8.18482L169.108 5.16807L183.511 4.93452L183.53 3.76675H169.828V0.75H187.491Z"
                  fill="#414756"
                />
                <path
                  d="M156.183 3.2028C156.173 6.72558 158.908 10.7057 165.058 11.7178L163.326 15.0168C158.801 14.1507 155.687 11.7665 154.071 8.67186C152.427 11.7373 149.303 14.1312 144.817 15.0168L143.133 11.7178C149.147 10.6765 151.921 6.66719 151.94 3.2028V1.44141H156.183V3.2028ZM142.686 22.2667V19.0261H165.759V22.2667H142.686Z"
                  fill="#414756"
                />
                <path
                  d="M139.544 0V14.967H135.563V0H139.544ZM119.721 13.7213V1.86844H131.827V5.02142H123.642V10.5683C128.207 10.5391 130.863 10.3445 133.773 9.7801L134.104 12.9428C130.815 13.6143 127.759 13.7311 121.949 13.7213H119.721ZM122.154 20.2998C122.154 17.1273 125.462 15.2589 130.902 15.2784C136.225 15.2589 139.602 17.1273 139.621 20.2998C139.602 23.443 136.225 25.3115 130.902 25.3115C125.462 25.3115 122.154 23.443 122.154 20.2998ZM126.143 20.2998C126.134 21.6233 127.71 22.2363 130.902 22.2363C134.055 22.2363 135.7 21.6233 135.719 20.2998C135.7 18.9179 134.055 18.373 130.902 18.373C127.71 18.373 126.134 18.9179 126.143 20.2998Z"
                  fill="#414756"
                />
                <path
                  d="M114.868 2.01367V4.17405C114.849 7.21999 114.907 10.8304 113.681 16.3773L109.808 15.9977C110.197 14.2072 110.46 12.5917 110.625 11.1223L96.2323 11.9203L95.6971 8.57266L110.878 8.09582C110.927 7.03509 110.946 6.06195 110.956 5.16666H96.5534V2.01367H114.868ZM93.9746 22.5178V19.3064H101.964V13.3703H105.896V19.3064H116.999V22.5178H93.9746Z"
                  fill="#414756"
                />
                <path
                  d="M92.6904 19.1139V22.3155H69.6172V19.1139H92.6904ZM70.4736 12.4965C76.2054 11.6401 78.8231 8.42874 78.9983 5.16871H71.5343V2.04492H90.6663V5.16871H83.2023C83.3677 8.41901 86.0049 11.5136 91.9314 12.3797L90.3744 15.5132C85.8492 14.7444 82.7449 12.6814 81.1295 9.85927C79.5627 12.7592 76.507 14.9099 72.0209 15.6884L70.4736 12.4965Z"
                  fill="#414756"
                />
                <path
                  d="M56.7623 1.19629C62.2119 1.19629 66.3478 3.99894 66.3575 8.20293C66.3478 12.0371 63.1656 14.6159 58.7086 15.1804V19.18H68.333V22.4011H45.2598V19.18H54.7868V15.1804C50.3493 14.6062 47.1963 12.0274 47.2061 8.20293C47.1963 3.99894 51.3127 1.19629 56.7623 1.19629ZM51.1278 8.20293C51.1181 10.7818 53.405 12.1539 56.7623 12.1539C60.1294 12.1539 62.4163 10.7818 62.426 8.20293C62.4163 5.78953 60.1294 4.34927 56.7623 4.33954C53.405 4.34927 51.1181 5.78953 51.1278 8.20293Z"
                  fill="#414756"
                />
              </svg>
            ),
            defaultImgMob: (
              <svg
                width="190"
                height="26"
                viewBox="0 0 190 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.04295 21.6875C3.84487 21.6875 0 18.0361 0 12.6553C0 7.27437 3.84487 3.62305 9.04295 3.62305C14.241 3.62305 18.0644 7.27437 18.0644 12.6553C18.0644 18.0361 14.241 21.6875 9.04295 21.6875ZM4.59665 12.6553C4.59665 15.4525 6.40095 17.3956 9.04295 17.3956C11.685 17.3956 13.4678 15.4525 13.4678 12.6553C13.4678 9.85805 11.685 7.91495 9.04295 7.91495C6.40095 7.91495 4.59665 9.85805 4.59665 12.6553Z"
                  fill="#414756"
                />
                <path
                  d="M19.2949 17.7011L26.4053 8.20928H19.2949V4.03516H32.3511V7.5803L25.5472 17.0721H33.0867V21.2748H19.2949V17.7011Z"
                  fill="#414756"
                />
                <rect x="34.9902" y="17.1582" width="4.1174" height="4.1174" fill="#984BFF" />
                <path
                  d="M187.491 0.75V3.0077C187.491 4.93452 187.491 6.9392 187.053 9.34286H190.002V12.4277H180.494V13.8582H187.578V20.8162H173.487V21.9645H188.454V25.0007H169.566V18.0136H183.627V16.8555H169.536V13.8582H176.533V12.4277H167.104V9.34286H183.053C183.17 8.74924 183.248 8.19455 183.316 7.65932L169.546 8.18482L169.108 5.16807L183.511 4.93452L183.53 3.76675H169.828V0.75H187.491Z"
                  fill="#414756"
                />
                <path
                  d="M156.183 3.2028C156.173 6.72558 158.908 10.7057 165.058 11.7178L163.326 15.0168C158.801 14.1507 155.687 11.7665 154.071 8.67186C152.427 11.7373 149.303 14.1312 144.817 15.0168L143.133 11.7178C149.147 10.6765 151.921 6.66719 151.94 3.2028V1.44141H156.183V3.2028ZM142.686 22.2667V19.0261H165.759V22.2667H142.686Z"
                  fill="#414756"
                />
                <path
                  d="M139.544 0V14.967H135.563V0H139.544ZM119.721 13.7213V1.86844H131.827V5.02142H123.642V10.5683C128.207 10.5391 130.863 10.3445 133.773 9.7801L134.104 12.9428C130.815 13.6143 127.759 13.7311 121.949 13.7213H119.721ZM122.154 20.2998C122.154 17.1273 125.462 15.2589 130.902 15.2784C136.225 15.2589 139.602 17.1273 139.621 20.2998C139.602 23.443 136.225 25.3115 130.902 25.3115C125.462 25.3115 122.154 23.443 122.154 20.2998ZM126.143 20.2998C126.134 21.6233 127.71 22.2363 130.902 22.2363C134.055 22.2363 135.7 21.6233 135.719 20.2998C135.7 18.9179 134.055 18.373 130.902 18.373C127.71 18.373 126.134 18.9179 126.143 20.2998Z"
                  fill="#414756"
                />
                <path
                  d="M114.868 2.01367V4.17405C114.849 7.21999 114.907 10.8304 113.681 16.3773L109.808 15.9977C110.197 14.2072 110.46 12.5917 110.625 11.1223L96.2323 11.9203L95.6971 8.57266L110.878 8.09582C110.927 7.03509 110.946 6.06195 110.956 5.16666H96.5534V2.01367H114.868ZM93.9746 22.5178V19.3064H101.964V13.3703H105.896V19.3064H116.999V22.5178H93.9746Z"
                  fill="#414756"
                />
                <path
                  d="M92.6904 19.1139V22.3155H69.6172V19.1139H92.6904ZM70.4736 12.4965C76.2054 11.6401 78.8231 8.42874 78.9983 5.16871H71.5343V2.04492H90.6663V5.16871H83.2023C83.3677 8.41901 86.0049 11.5136 91.9314 12.3797L90.3744 15.5132C85.8492 14.7444 82.7449 12.6814 81.1295 9.85927C79.5627 12.7592 76.507 14.9099 72.0209 15.6884L70.4736 12.4965Z"
                  fill="#414756"
                />
                <path
                  d="M56.7623 1.19629C62.2119 1.19629 66.3478 3.99894 66.3575 8.20293C66.3478 12.0371 63.1656 14.6159 58.7086 15.1804V19.18H68.333V22.4011H45.2598V19.18H54.7868V15.1804C50.3493 14.6062 47.1963 12.0274 47.2061 8.20293C47.1963 3.99894 51.3127 1.19629 56.7623 1.19629ZM51.1278 8.20293C51.1181 10.7818 53.405 12.1539 56.7623 12.1539C60.1294 12.1539 62.4163 10.7818 62.426 8.20293C62.4163 5.78953 60.1294 4.34927 56.7623 4.33954C53.405 4.34927 51.1181 5.78953 51.1278 8.20293Z"
                  fill="#414756"
                />
              </svg>
            ),
            hoverImg: (
              <svg
                width="190"
                height="26"
                viewBox="0 0 190 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.04295 21.6875C3.84487 21.6875 0 18.0361 0 12.6553C0 7.27437 3.84487 3.62305 9.04295 3.62305C14.241 3.62305 18.0644 7.27437 18.0644 12.6553C18.0644 18.0361 14.241 21.6875 9.04295 21.6875ZM4.59665 12.6553C4.59665 15.4525 6.40095 17.3956 9.04295 17.3956C11.685 17.3956 13.4678 15.4525 13.4678 12.6553C13.4678 9.85805 11.685 7.91495 9.04295 7.91495C6.40095 7.91495 4.59665 9.85805 4.59665 12.6553Z"
                  fill="#000A30"
                />
                <path
                  d="M19.2949 17.7011L26.4053 8.20928H19.2949V4.03516H32.3511V7.5803L25.5472 17.0721H33.0867V21.2748H19.2949V17.7011Z"
                  fill="#000A30"
                />
                <rect x="34.9902" y="17.1582" width="4.1174" height="4.1174" fill="#984BFF" />
                <path
                  d="M187.491 0.75V3.0077C187.491 4.93452 187.491 6.9392 187.053 9.34286H190.002V12.4277H180.494V13.8582H187.578V20.8162H173.487V21.9645H188.454V25.0007H169.566V18.0136H183.627V16.8555H169.536V13.8582H176.533V12.4277H167.104V9.34286H183.053C183.17 8.74924 183.248 8.19455 183.316 7.65932L169.546 8.18482L169.108 5.16807L183.511 4.93452L183.53 3.76675H169.828V0.75H187.491Z"
                  fill="#000A30"
                />
                <path
                  d="M156.183 3.2028C156.173 6.72558 158.908 10.7057 165.058 11.7178L163.326 15.0168C158.801 14.1507 155.687 11.7665 154.071 8.67186C152.427 11.7373 149.303 14.1312 144.817 15.0168L143.133 11.7178C149.147 10.6765 151.921 6.66719 151.94 3.2028V1.44141H156.183V3.2028ZM142.686 22.2667V19.0261H165.759V22.2667H142.686Z"
                  fill="#000A30"
                />
                <path
                  d="M139.544 0V14.967H135.563V0H139.544ZM119.721 13.7213V1.86844H131.827V5.02142H123.642V10.5683C128.207 10.5391 130.863 10.3445 133.773 9.7801L134.104 12.9428C130.815 13.6143 127.759 13.7311 121.949 13.7213H119.721ZM122.154 20.2998C122.154 17.1273 125.462 15.2589 130.902 15.2784C136.225 15.2589 139.602 17.1273 139.621 20.2998C139.602 23.443 136.225 25.3115 130.902 25.3115C125.462 25.3115 122.154 23.443 122.154 20.2998ZM126.143 20.2998C126.134 21.6233 127.71 22.2363 130.902 22.2363C134.055 22.2363 135.7 21.6233 135.719 20.2998C135.7 18.9179 134.055 18.373 130.902 18.373C127.71 18.373 126.134 18.9179 126.143 20.2998Z"
                  fill="#000A30"
                />
                <path
                  d="M114.868 2.01367V4.17405C114.849 7.21999 114.907 10.8304 113.681 16.3773L109.808 15.9977C110.197 14.2072 110.46 12.5917 110.625 11.1223L96.2323 11.9203L95.6971 8.57266L110.878 8.09582C110.927 7.03509 110.946 6.06195 110.956 5.16666H96.5534V2.01367H114.868ZM93.9746 22.5178V19.3064H101.964V13.3703H105.896V19.3064H116.999V22.5178H93.9746Z"
                  fill="#000A30"
                />
                <path
                  d="M92.6904 19.1139V22.3155H69.6172V19.1139H92.6904ZM70.4736 12.4965C76.2054 11.6401 78.8231 8.42874 78.9983 5.16871H71.5343V2.04492H90.6663V5.16871H83.2023C83.3677 8.41901 86.0049 11.5136 91.9314 12.3797L90.3744 15.5132C85.8492 14.7444 82.7449 12.6814 81.1295 9.85927C79.5627 12.7592 76.507 14.9099 72.0209 15.6884L70.4736 12.4965Z"
                  fill="#000A30"
                />
                <path
                  d="M56.7623 1.19629C62.2119 1.19629 66.3478 3.99894 66.3575 8.20293C66.3478 12.0371 63.1656 14.6159 58.7086 15.1804V19.18H68.333V22.4011H45.2598V19.18H54.7868V15.1804C50.3493 14.6062 47.1963 12.0274 47.2061 8.20293C47.1963 3.99894 51.3127 1.19629 56.7623 1.19629ZM51.1278 8.20293C51.1181 10.7818 53.405 12.1539 56.7623 12.1539C60.1294 12.1539 62.4163 10.7818 62.426 8.20293C62.4163 5.78953 60.1294 4.34927 56.7623 4.33954C53.405 4.34927 51.1181 5.78953 51.1278 8.20293Z"
                  fill="#000A30"
                />
              </svg>
            ),
            hoverImgMob: (
              <svg
                width="190"
                height="26"
                viewBox="0 0 190 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.04295 21.6875C3.84487 21.6875 0 18.0361 0 12.6553C0 7.27437 3.84487 3.62305 9.04295 3.62305C14.241 3.62305 18.0644 7.27437 18.0644 12.6553C18.0644 18.0361 14.241 21.6875 9.04295 21.6875ZM4.59665 12.6553C4.59665 15.4525 6.40095 17.3956 9.04295 17.3956C11.685 17.3956 13.4678 15.4525 13.4678 12.6553C13.4678 9.85805 11.685 7.91495 9.04295 7.91495C6.40095 7.91495 4.59665 9.85805 4.59665 12.6553Z"
                  fill="#000A30"
                />
                <path
                  d="M19.2949 17.7011L26.4053 8.20928H19.2949V4.03516H32.3511V7.5803L25.5472 17.0721H33.0867V21.2748H19.2949V17.7011Z"
                  fill="#000A30"
                />
                <rect x="34.9902" y="17.1582" width="4.1174" height="4.1174" fill="#984BFF" />
                <path
                  d="M187.491 0.75V3.0077C187.491 4.93452 187.491 6.9392 187.053 9.34286H190.002V12.4277H180.494V13.8582H187.578V20.8162H173.487V21.9645H188.454V25.0007H169.566V18.0136H183.627V16.8555H169.536V13.8582H176.533V12.4277H167.104V9.34286H183.053C183.17 8.74924 183.248 8.19455 183.316 7.65932L169.546 8.18482L169.108 5.16807L183.511 4.93452L183.53 3.76675H169.828V0.75H187.491Z"
                  fill="#000A30"
                />
                <path
                  d="M156.183 3.2028C156.173 6.72558 158.908 10.7057 165.058 11.7178L163.326 15.0168C158.801 14.1507 155.687 11.7665 154.071 8.67186C152.427 11.7373 149.303 14.1312 144.817 15.0168L143.133 11.7178C149.147 10.6765 151.921 6.66719 151.94 3.2028V1.44141H156.183V3.2028ZM142.686 22.2667V19.0261H165.759V22.2667H142.686Z"
                  fill="#000A30"
                />
                <path
                  d="M139.544 0V14.967H135.563V0H139.544ZM119.721 13.7213V1.86844H131.827V5.02142H123.642V10.5683C128.207 10.5391 130.863 10.3445 133.773 9.7801L134.104 12.9428C130.815 13.6143 127.759 13.7311 121.949 13.7213H119.721ZM122.154 20.2998C122.154 17.1273 125.462 15.2589 130.902 15.2784C136.225 15.2589 139.602 17.1273 139.621 20.2998C139.602 23.443 136.225 25.3115 130.902 25.3115C125.462 25.3115 122.154 23.443 122.154 20.2998ZM126.143 20.2998C126.134 21.6233 127.71 22.2363 130.902 22.2363C134.055 22.2363 135.7 21.6233 135.719 20.2998C135.7 18.9179 134.055 18.373 130.902 18.373C127.71 18.373 126.134 18.9179 126.143 20.2998Z"
                  fill="#000A30"
                />
                <path
                  d="M114.868 2.01367V4.17405C114.849 7.21999 114.907 10.8304 113.681 16.3773L109.808 15.9977C110.197 14.2072 110.46 12.5917 110.625 11.1223L96.2323 11.9203L95.6971 8.57266L110.878 8.09582C110.927 7.03509 110.946 6.06195 110.956 5.16666H96.5534V2.01367H114.868ZM93.9746 22.5178V19.3064H101.964V13.3703H105.896V19.3064H116.999V22.5178H93.9746Z"
                  fill="#000A30"
                />
                <path
                  d="M92.6904 19.1139V22.3155H69.6172V19.1139H92.6904ZM70.4736 12.4965C76.2054 11.6401 78.8231 8.42874 78.9983 5.16871H71.5343V2.04492H90.6663V5.16871H83.2023C83.3677 8.41901 86.0049 11.5136 91.9314 12.3797L90.3744 15.5132C85.8492 14.7444 82.7449 12.6814 81.1295 9.85927C79.5627 12.7592 76.507 14.9099 72.0209 15.6884L70.4736 12.4965Z"
                  fill="#000A30"
                />
                <path
                  d="M56.7623 1.19629C62.2119 1.19629 66.3478 3.99894 66.3575 8.20293C66.3478 12.0371 63.1656 14.6159 58.7086 15.1804V19.18H68.333V22.4011H45.2598V19.18H54.7868V15.1804C50.3493 14.6062 47.1963 12.0274 47.2061 8.20293C47.1963 3.99894 51.3127 1.19629 56.7623 1.19629ZM51.1278 8.20293C51.1181 10.7818 53.405 12.1539 56.7623 12.1539C60.1294 12.1539 62.4163 10.7818 62.426 8.20293C62.4163 5.78953 60.1294 4.34927 56.7623 4.33954C53.405 4.34927 51.1181 5.78953 51.1278 8.20293Z"
                  fill="#000A30"
                />
              </svg>
            ),
          },
          {
            defaultImg: (
              <svg
                width="231"
                height="25"
                viewBox="0 0 231 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M220.686 0C226.008 0 229.985 3.24562 230.248 7.77778H224.751C224.429 5.70176 222.879 4.44444 220.335 4.44444C217.85 4.44444 216.476 5.4386 216.476 6.87135C216.476 8.65497 218.318 9.26901 222.967 10.2339C228.464 11.3743 230.335 14.0936 230.335 17.6608C230.335 22.2222 226.037 25 220.716 25C214.838 25 210.54 21.8129 210.306 16.7251H215.774C216.066 19.0058 218.055 20.5263 221.096 20.5263C223.259 20.5263 224.868 19.4444 224.868 17.8947C224.868 15.9942 223.259 15.2339 219.02 14.4444C213.844 13.4795 211.037 11.345 211.037 6.87135C211.037 2.5731 215.306 0 220.686 0Z"
                  fill="#414756"
                />
                <path
                  d="M209.181 24.4154H203.012L198.392 16.9008H194.152V24.4154H188.831V0.584961H198.421C204.474 0.584961 208.012 3.80134 208.012 8.74286C208.012 12.0177 206.462 14.5323 203.655 15.8481L209.181 24.4154ZM194.152 5.29256V12.1931H198.187C200.994 12.1931 202.69 10.9943 202.69 8.74286C202.69 6.49139 200.994 5.29256 198.187 5.29256H194.152Z"
                  fill="#414756"
                />
                <path
                  d="M167.272 24.4154V0.584961H184.991V5.20484H172.594V10.0586H183.968V14.6785H172.594V19.7955H185.254V24.4154H167.272Z"
                  fill="#414756"
                />
                <path
                  d="M141.402 24.4158V0.585366H145.846L157.747 14.9129V0.292969H163.068V24.1234H158.624L146.723 9.79589V24.4158H141.402Z"
                  fill="#414756"
                />
                <path
                  d="M138.546 0.584961V5.29256H130.797V24.4154H125.476V5.29256H117.727V0.584961H138.546Z"
                  fill="#414756"
                />
                <path
                  d="M117.093 24.4154H110.924L106.304 16.9008H102.064V24.4154H96.7426V0.584961H106.333C112.386 0.584961 115.924 3.80134 115.924 8.74286C115.924 12.0177 114.374 14.5323 111.567 15.8481L117.093 24.4154ZM102.064 5.29256V12.1931H106.099C108.906 12.1931 110.602 10.9943 110.602 8.74286C110.602 6.49139 108.906 5.29256 106.099 5.29256H102.064Z"
                  fill="#414756"
                />
                <path
                  d="M88.4144 24.4154L86.7477 19.7955H77.508L75.8413 24.4154H70.3442L79.584 0.584961H85.1103L94.3501 24.4154H88.4144ZM79.1454 15.1756H85.1103L82.1279 6.87151L79.1454 15.1756Z"
                  fill="#414756"
                />
                <path
                  d="M52.7686 24.4154V0.584961H61.9206C68.1194 0.584961 71.7452 4.03525 71.7452 9.29841C71.7452 14.62 68.1194 18.0703 61.9206 18.0703H58.0902V24.4154H52.7686ZM58.0902 13.3627H61.6867C64.6399 13.3627 66.4235 11.9592 66.4235 9.29841C66.4235 6.66683 64.6399 5.29256 61.6867 5.29256H58.0902V13.3627Z"
                  fill="#414756"
                />
                <path
                  d="M24.1571 24.4154V0.584961H41.6425V5.26332H29.4787V10.6727H40.2975V15.2926H29.4787V24.4154H24.1571Z"
                  fill="#414756"
                />
                <path
                  d="M15.2339 24.4154L8.56725 15.3218L5.32164 19.0645V24.4154H0V0.584961H5.32164V11.813L14.8538 0.584961H21.3743L12.0175 11.3744L21.8713 24.4154H15.2339Z"
                  fill="#414756"
                />
              </svg>
            ),
            defaultImgMob: (
              <svg
                width="167"
                height="18"
                viewBox="0 0 167 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M159.802 0C163.655 0 166.535 2.33684 166.725 5.6H162.745C162.512 4.10526 161.39 3.2 159.547 3.2C157.748 3.2 156.753 3.91579 156.753 4.94737C156.753 6.23158 158.087 6.67368 161.453 7.36842C165.434 8.18947 166.789 10.1474 166.789 12.7158C166.789 16 163.676 18 159.823 18C155.567 18 152.455 15.7053 152.285 12.0421H156.244C156.456 13.6842 157.896 14.7789 160.098 14.7789C161.665 14.7789 162.829 14 162.829 12.8842C162.829 11.5158 161.665 10.9684 158.595 10.4C154.847 9.70526 152.814 8.16842 152.814 4.94737C152.814 1.85263 155.906 0 159.802 0Z"
                  fill="#414756"
                />
                <path
                  d="M151.471 17.5778H147.003L143.658 12.1673H140.588V17.5778H136.734V0.419922H143.679C148.062 0.419922 150.624 2.73571 150.624 6.29361C150.624 8.6515 149.502 10.462 147.469 11.4094L151.471 17.5778ZM140.588 3.8094V8.77782H143.51C145.542 8.77782 146.77 7.91466 146.77 6.29361C146.77 4.67255 145.542 3.8094 143.51 3.8094H140.588Z"
                  fill="#414756"
                />
                <path
                  d="M121.125 17.5778V0.419922H133.956V3.74624H124.978V7.24097H133.215V10.5673H124.978V14.2515H134.146V17.5778H121.125Z"
                  fill="#414756"
                />
                <path
                  d="M102.395 17.5813V0.423417H105.613L114.23 10.7392V0.212891H118.084V17.3708H114.865L106.248 7.055V17.5813H102.395Z"
                  fill="#414756"
                />
                <path
                  d="M100.323 0.419922V3.8094H94.7124V17.5778H90.8589V3.8094H85.248V0.419922H100.323Z"
                  fill="#414756"
                />
                <path
                  d="M84.791 17.5778H80.3236L76.9782 12.1673H73.9082V17.5778H70.0547V0.419922H76.9994C81.3822 0.419922 83.9441 2.73571 83.9441 6.29361C83.9441 8.6515 82.822 10.462 80.7894 11.4094L84.791 17.5778ZM73.9082 3.8094V8.77782H76.83C78.8626 8.77782 80.0907 7.91466 80.0907 6.29361C80.0907 4.67255 78.8626 3.8094 76.83 3.8094H73.9082Z"
                  fill="#414756"
                />
                <path
                  d="M64.0204 17.5778L62.8136 14.2515H56.1229L54.9161 17.5778H50.9355L57.6262 0.419922H61.6279L68.3185 17.5778H64.0204ZM57.3086 10.9252H61.6279L59.4682 4.94624L57.3086 10.9252Z"
                  fill="#414756"
                />
                <path
                  d="M38.209 17.5778V0.419922H44.8361C49.3248 0.419922 51.9502 2.90413 51.9502 6.6936C51.9502 10.5252 49.3248 13.0094 44.8361 13.0094H42.0625V17.5778H38.209ZM42.0625 9.61992H44.6667C46.8052 9.61992 48.0967 8.60939 48.0967 6.6936C48.0967 4.79887 46.8052 3.8094 44.6667 3.8094H42.0625V9.61992Z"
                  fill="#414756"
                />
                <path
                  d="M17.4961 17.5778V0.419922H30.1575V3.78834H21.3496V7.68308H29.1836V11.0094H21.3496V17.5778H17.4961Z"
                  fill="#414756"
                />
                <path
                  d="M11.0311 17.5778L6.20367 11.0304L3.85347 13.7252V17.5778H0V0.419922H3.85347V8.50413L10.7559 0.419922H15.4774L8.70208 8.18834L15.8374 17.5778H11.0311Z"
                  fill="#414756"
                />
              </svg>
            ),
            hoverImg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="231"
                height="25"
                viewBox="0 0 231 25"
                fill="none"
              >
                <path
                  d="M220.686 0C226.008 0 229.985 3.24562 230.248 7.77778H224.751C224.429 5.70176 222.879 4.44444 220.335 4.44444C217.85 4.44444 216.476 5.4386 216.476 6.87135C216.476 8.65497 218.318 9.26901 222.967 10.2339C228.464 11.3743 230.335 14.0936 230.335 17.6608C230.335 22.2222 226.037 25 220.716 25C214.838 25 210.54 21.8129 210.306 16.7251H215.774C216.066 19.0058 218.055 20.5263 221.096 20.5263C223.259 20.5263 224.868 19.4444 224.868 17.8947C224.868 15.9942 223.259 15.2339 219.02 14.4444C213.844 13.4795 211.037 11.345 211.037 6.87135C211.037 2.5731 215.306 0 220.686 0Z"
                  fill="#FF5B00"
                />
                <path
                  d="M209.181 24.4154H203.012L198.392 16.9008H194.152V24.4154H188.831V0.584961H198.421C204.474 0.584961 208.012 3.80134 208.012 8.74286C208.012 12.0177 206.462 14.5323 203.655 15.8481L209.181 24.4154ZM194.152 5.29256V12.1931H198.187C200.994 12.1931 202.69 10.9943 202.69 8.74286C202.69 6.49139 200.994 5.29256 198.187 5.29256H194.152Z"
                  fill="#FF5B00"
                />
                <path
                  d="M167.272 24.4154V0.584961H184.991V5.20484H172.594V10.0586H183.968V14.6785H172.594V19.7955H185.254V24.4154H167.272Z"
                  fill="#FF5B00"
                />
                <path
                  d="M141.402 24.4158V0.585366H145.846L157.747 14.9129V0.292969H163.068V24.1234H158.624L146.723 9.79589V24.4158H141.402Z"
                  fill="#FF5B00"
                />
                <path
                  d="M138.546 0.584961V5.29256H130.797V24.4154H125.476V5.29256H117.727V0.584961H138.546Z"
                  fill="#FF5B00"
                />
                <path
                  d="M117.093 24.4154H110.924L106.304 16.9008H102.064V24.4154H96.7426V0.584961H106.333C112.386 0.584961 115.924 3.80134 115.924 8.74286C115.924 12.0177 114.374 14.5323 111.567 15.8481L117.093 24.4154ZM102.064 5.29256V12.1931H106.099C108.906 12.1931 110.602 10.9943 110.602 8.74286C110.602 6.49139 108.906 5.29256 106.099 5.29256H102.064Z"
                  fill="#FF5B00"
                />
                <path
                  d="M88.4144 24.4154L86.7477 19.7955H77.508L75.8413 24.4154H70.3442L79.584 0.584961H85.1103L94.3501 24.4154H88.4144ZM79.1454 15.1756H85.1103L82.1279 6.87151L79.1454 15.1756Z"
                  fill="#FF5B00"
                />
                <path
                  d="M52.7686 24.4154V0.584961H61.9206C68.1194 0.584961 71.7452 4.03525 71.7452 9.29841C71.7452 14.62 68.1194 18.0703 61.9206 18.0703H58.0902V24.4154H52.7686ZM58.0902 13.3627H61.6867C64.6399 13.3627 66.4235 11.9592 66.4235 9.29841C66.4235 6.66683 64.6399 5.29256 61.6867 5.29256H58.0902V13.3627Z"
                  fill="#FF5B00"
                />
                <path
                  d="M24.1571 24.4154V0.584961H41.6425V5.26332H29.4787V10.6727H40.2975V15.2926H29.4787V24.4154H24.1571Z"
                  fill="#FF5B00"
                />
                <path
                  d="M15.2339 24.4154L8.56725 15.3218L5.32164 19.0645V24.4154H0V0.584961H5.32164V11.813L14.8538 0.584961H21.3743L12.0175 11.3744L21.8713 24.4154H15.2339Z"
                  fill="#FF5B00"
                />
              </svg>
            ),
            hoverImgMob: (
              <svg
                width="167"
                height="18"
                viewBox="0 0 167 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M159.802 0C163.655 0 166.535 2.33684 166.725 5.6H162.745C162.512 4.10526 161.39 3.2 159.547 3.2C157.748 3.2 156.753 3.91579 156.753 4.94737C156.753 6.23158 158.087 6.67368 161.453 7.36842C165.434 8.18947 166.789 10.1474 166.789 12.7158C166.789 16 163.676 18 159.823 18C155.567 18 152.455 15.7053 152.285 12.0421H156.244C156.456 13.6842 157.896 14.7789 160.098 14.7789C161.665 14.7789 162.829 14 162.829 12.8842C162.829 11.5158 161.665 10.9684 158.595 10.4C154.847 9.70526 152.814 8.16842 152.814 4.94737C152.814 1.85263 155.906 0 159.802 0Z"
                  fill="#FF5B00"
                />
                <path
                  d="M151.471 17.5778H147.003L143.658 12.1673H140.588V17.5778H136.734V0.419922H143.679C148.062 0.419922 150.624 2.73571 150.624 6.29361C150.624 8.6515 149.502 10.462 147.469 11.4094L151.471 17.5778ZM140.588 3.8094V8.77782H143.51C145.542 8.77782 146.77 7.91466 146.77 6.29361C146.77 4.67255 145.542 3.8094 143.51 3.8094H140.588Z"
                  fill="#FF5B00"
                />
                <path
                  d="M121.125 17.5778V0.419922H133.956V3.74624H124.978V7.24097H133.215V10.5673H124.978V14.2515H134.146V17.5778H121.125Z"
                  fill="#FF5B00"
                />
                <path
                  d="M102.395 17.5813V0.423417H105.613L114.23 10.7392V0.212891H118.084V17.3708H114.865L106.248 7.055V17.5813H102.395Z"
                  fill="#FF5B00"
                />
                <path
                  d="M100.323 0.419922V3.8094H94.7124V17.5778H90.8589V3.8094H85.248V0.419922H100.323Z"
                  fill="#FF5B00"
                />
                <path
                  d="M84.791 17.5778H80.3236L76.9782 12.1673H73.9082V17.5778H70.0547V0.419922H76.9994C81.3822 0.419922 83.9441 2.73571 83.9441 6.29361C83.9441 8.6515 82.822 10.462 80.7894 11.4094L84.791 17.5778ZM73.9082 3.8094V8.77782H76.83C78.8626 8.77782 80.0907 7.91466 80.0907 6.29361C80.0907 4.67255 78.8626 3.8094 76.83 3.8094H73.9082Z"
                  fill="#FF5B00"
                />
                <path
                  d="M64.0204 17.5778L62.8136 14.2515H56.1229L54.9161 17.5778H50.9355L57.6262 0.419922H61.6279L68.3185 17.5778H64.0204ZM57.3086 10.9252H61.6279L59.4682 4.94624L57.3086 10.9252Z"
                  fill="#FF5B00"
                />
                <path
                  d="M38.209 17.5778V0.419922H44.8361C49.3248 0.419922 51.9502 2.90413 51.9502 6.6936C51.9502 10.5252 49.3248 13.0094 44.8361 13.0094H42.0625V17.5778H38.209ZM42.0625 9.61992H44.6667C46.8052 9.61992 48.0967 8.60939 48.0967 6.6936C48.0967 4.79887 46.8052 3.8094 44.6667 3.8094H42.0625V9.61992Z"
                  fill="#FF5B00"
                />
                <path
                  d="M17.4961 17.5778V0.419922H30.1575V3.78834H21.3496V7.68308H29.1836V11.0094H21.3496V17.5778H17.4961Z"
                  fill="#FF5B00"
                />
                <path
                  d="M11.0311 17.5778L6.20367 11.0304L3.85347 13.7252V17.5778H0V0.419922H3.85347V8.50413L10.7559 0.419922H15.4774L8.70208 8.18834L15.8374 17.5778H11.0311Z"
                  fill="#FF5B00"
                />
              </svg>
            ),
          },
          {
            defaultImg: <img src={sellfarm_default} alt="셀팜 브랜드 로고" />,
            defaultImgMob: <img src={sellfarm_default} alt="셀팜 브랜드 로고" />,
            hoverImg: <img src={sellfarm_hover} alt="셀팜 브랜드 로고" />,
            hoverImgMob: <img src={sellfarm_hover} alt="셀팜 브랜드 로고" />,
          },
          {
            defaultImg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="30"
                viewBox="0 0 150 30"
                fill="none"
              >
                <path
                  d="M48.941 29.7463L48.8165 27.6387C47.1529 28.7337 44.9898 29.9983 43.9502 29.9983C39.375 29.9983 36.8804 27.5127 36.8804 24.226C36.8804 22.9614 37.5044 21.0233 38.2529 20.0963C39.834 18.8737 44.0762 17.1891 47.0704 17.1891C47.4859 17.1891 48.1099 17.2311 48.7339 17.3151V16.5981C48.7339 13.6069 47.2369 11.3733 44.2007 11.3733C42.8282 11.7528 40.9561 13.6489 40.2075 14.702L39.21 14.66L37.2974 12.3844C38.712 11.2053 42.2056 8.92969 44.4512 8.92969C49.775 8.92969 52.1451 12.5539 52.1451 16.2621V25.7006L52.3116 29.0292L51.6456 29.7463H48.9425H48.941ZM44.9073 27.1766C45.9048 26.7971 47.6524 25.8701 48.7339 25.027V19.8863C47.8189 19.7182 46.8619 19.6327 46.3218 19.6327C44.8248 19.6327 42.1216 20.5598 41.0821 21.2333C40.5826 21.9504 40.209 22.7079 40.209 23.8885C40.209 26.3741 41.9971 27.1751 44.9088 27.1751L44.9073 27.1766Z"
                  fill="#414756"
                />
                <path
                  d="M64.6661 27.3017C65.6231 26.7122 67.0362 25.2796 67.7847 24.3106L68.4912 24.2266L70.1968 25.8692C68.3247 27.9333 65.6216 29.9989 64.207 29.9989C60.9624 29.9989 59.2583 28.1448 59.2583 24.4366V11.6274L55.3911 11.7954L54.9756 11.0784L55.8081 8.97079L59.2598 9.1388V6.61117L59.0933 3.07099L59.6753 2.43945H62.9605L62.752 6.52716V9.1823L68.5332 8.97079L68.9488 9.68783L68.1162 11.7954L62.7505 11.5854V23.8891C62.7505 26.0807 63.166 27.3017 64.6631 27.3017H64.6661Z"
                  fill="#414756"
                />
                <path
                  d="M81.3841 11.6253C80.511 11.7513 78.3899 12.7204 77.4749 13.7329C76.4353 15.545 76.1443 17.7366 76.1443 19.6747C76.1443 24.184 78.972 27.3011 83.0477 27.3011C84.5867 27.0911 86.4993 25.489 87.4144 24.268L88.1209 24.184L89.8265 25.4485C88.2049 27.5126 85.0428 29.9998 82.1731 29.9998C76.0183 29.9998 72.6492 25.1965 72.6492 19.8022C72.6492 17.9062 73.2312 15.6725 74.1042 13.9024C75.9343 11.4168 79.3035 8.92969 82.1731 8.92969C85.3338 8.92969 87.7879 10.3623 89.327 12.5539L89.1185 13.4809L86.6223 15.0395C85.4988 13.1854 83.6282 11.6268 81.3811 11.6268L81.3841 11.6253Z"
                  fill="#414756"
                />
                <path
                  d="M107.006 25.7893V17.7834C107.006 14.5388 106.049 11.6316 102.597 11.6316C101.682 11.7576 99.0613 13.6957 97.7728 14.7503V25.7053L97.9393 29.034L97.2732 29.751H94.1531L94.3616 25.7908V3.9617L94.1531 0.717037L94.8191 0H97.9798L97.7713 4.04571V11.8416C99.7258 10.325 101.889 8.93446 103.386 8.93446C108.918 8.93446 110.415 13.3162 110.415 17.4459V25.7053L110.582 29.034L109.916 29.751H106.797L107.006 25.7908V25.7893Z"
                  fill="#414756"
                />
                <path
                  d="M115.107 19.8023C115.107 17.9062 115.689 15.6725 116.562 13.9024C118.392 11.4168 121.761 8.92969 124.631 8.92969C128.831 8.92969 132.409 11.9628 132.409 18.0727L130.205 19.9688H118.436C118.56 24.3085 121.514 27.3011 125.505 27.3011C127.169 27.0911 128.833 25.7846 129.748 24.7735L130.454 24.6895L132.16 25.8686C130.538 27.7227 127.627 29.9983 124.632 29.9983C118.478 29.9983 115.108 25.195 115.108 19.8008L115.107 19.8023ZM129.164 17.3586C129.164 14.4935 127.376 11.6283 123.674 11.6283C122.801 11.7543 120.762 12.7234 119.847 13.7359C119.223 14.831 118.808 16.0956 118.599 17.3601H129.163L129.164 17.3586Z"
                  fill="#414756"
                />
                <path
                  d="M137.36 25.7846V13.1434L137.151 9.89874L137.817 9.1817H140.645L140.812 11.8788C142.808 10.3203 144.971 8.92969 146.468 8.92969C147.632 8.92969 149.213 10.1087 150.004 11.0373L149.879 11.8803L147.467 14.072C147.052 13.2289 146.179 12.0919 145.471 11.8383C144.556 11.9643 142.102 13.6924 140.854 14.7455V25.7006L141.02 29.0292L140.354 29.7463H137.151L137.36 25.7861V25.7846Z"
                  fill="#414756"
                />
                <path
                  d="M33.9428 0.342773L32.8613 1.48133L26.33 22.2604L23.4468 14.025C23.2218 13.3799 22.3548 13.2959 22.0113 13.884L20.6747 16.1791C19.4896 18.2117 19.2796 20.6793 20.1017 22.8889L22.6278 29.6783H25.955L27.2855 28.5397L36.8965 0.342773H33.9428Z"
                  fill="#414756"
                />
                <path
                  d="M24.7492 0.342773L23.6676 1.48133L22.2725 5.54504C21.6949 7.22513 21.7024 9.05522 22.292 10.7323L22.8785 12.3989L27.7449 0.342773H24.7492Z"
                  fill="#414756"
                />
                <path
                  d="M18.6365 0.342773H13.5601L12.27 1.69284L16.7569 13.8015C16.9894 14.43 17.843 14.4975 18.1685 13.9125L19.6551 11.2408C20.7517 9.27273 20.9497 6.91911 20.2012 4.789L18.635 0.342773H18.6365Z"
                  fill="#414756"
                />
                <path
                  d="M14.4352 22.6414L6.48931 0.342773H1.29006L0 1.69284L10.5665 29.6783H13.8937L15.2242 28.5397L17.7159 22.1719C18.4164 20.3823 18.4404 18.3947 17.7834 16.5886L17.3468 15.3901L14.4352 22.6414Z"
                  fill="#414756"
                />
              </svg>
            ),
            defaultImgMob: (
              <svg
                width="131"
                height="26"
                viewBox="0 0 131 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.6606 25.7811L42.5521 23.9546C41.1021 24.9036 39.2167 25.9995 38.3106 25.9995C34.3228 25.9995 32.1484 23.8453 32.1484 20.9969C32.1484 19.901 32.6923 18.2213 33.3448 17.4179C34.7229 16.3583 38.4204 14.8984 41.0302 14.8984C41.3923 14.8984 41.9362 14.9348 42.4802 15.0076V14.3861C42.4802 11.7938 41.1753 9.85803 38.529 9.85803C37.3326 10.1869 35.7009 11.8302 35.0484 12.7429L34.179 12.7065L32.5119 10.7343C33.7449 9.71242 36.79 7.74023 38.7473 7.74023C43.3876 7.74023 45.4534 10.8812 45.4534 14.0949V22.2749L45.5985 25.1597L45.018 25.7811H42.6619H42.6606ZM39.1448 23.5541C40.0142 23.2252 41.5375 22.4218 42.4802 21.6912V17.2359C41.6826 17.0902 40.8484 17.0161 40.3777 17.0161C39.0729 17.0161 36.7168 17.8196 35.8107 18.4033C35.3753 19.0247 35.0497 19.6813 35.0497 20.7044C35.0497 22.8586 36.6083 23.5528 39.1461 23.5528L39.1448 23.5541Z"
                  fill="#414756"
                />
                <path
                  d="M56.3663 23.6585C57.2004 23.1475 58.4321 21.906 59.0845 21.0662L59.7003 20.9934L61.1869 22.4169C59.5552 24.2058 57.1991 25.996 55.9662 25.996C53.1381 25.996 51.6528 24.3891 51.6528 21.1754V10.0742L48.2821 10.2198L47.9199 9.59836L48.6456 7.77178L51.6541 7.91738V5.72679L51.509 2.65865L52.0163 2.11133H54.8796L54.6979 5.65398V7.95508L59.7369 7.77178L60.0991 8.3932L59.3735 10.2198L54.6966 10.0378V20.7008C54.6966 22.6002 55.0588 23.6585 56.3636 23.6585H56.3663Z"
                  fill="#414756"
                />
                <path
                  d="M70.9396 10.0764C70.1787 10.1856 68.3299 11.0255 67.5323 11.903C66.6263 13.4735 66.3726 15.3729 66.3726 17.0525C66.3726 20.9605 68.8372 23.662 72.3896 23.662C73.7311 23.48 75.3981 22.0916 76.1957 21.0333L76.8115 20.9605L78.2981 22.0565C76.8848 23.8453 74.1286 26.0008 71.6274 26.0008C66.2628 26.0008 63.3262 21.8381 63.3262 17.163C63.3262 15.5198 63.8335 13.584 64.5944 12.0499C66.1896 9.89573 69.1262 7.74023 71.6274 7.74023C74.3822 7.74023 76.5213 8.98179 77.8628 10.8812L77.681 11.6846L75.5054 13.0354C74.5261 11.4285 72.8956 10.0777 70.937 10.0777L70.9396 10.0764Z"
                  fill="#414756"
                />
                <path
                  d="M93.2709 22.3506V15.4122C93.2709 12.6002 92.4367 10.0806 89.4282 10.0806C88.6306 10.1898 86.3464 11.8695 85.2233 12.7835V22.2778L85.3684 25.1626L84.7879 25.784H82.0684L82.2501 22.3519V3.43345L82.0684 0.621427L82.6489 0H85.4037L85.222 3.50625V10.2627C86.9257 8.94829 88.811 7.74314 90.1159 7.74314C94.9379 7.74314 96.2428 11.5406 96.2428 15.1197V22.2778L96.3879 25.1626L95.8074 25.784H93.0891L93.2709 22.3519V22.3506Z"
                  fill="#414756"
                />
                <path
                  d="M100.332 17.1631C100.332 15.5198 100.839 13.584 101.6 12.0499C103.195 9.89573 106.132 7.74023 108.633 7.74023C112.294 7.74023 115.413 10.369 115.413 15.6641L113.492 17.3074H103.233C103.342 21.0684 105.916 23.662 109.395 23.662C110.845 23.48 112.295 22.3477 113.093 21.4714L113.709 21.3986L115.195 22.4205C113.782 24.0274 111.244 25.9995 108.635 25.9995C103.27 25.9995 100.333 21.8368 100.333 17.1618L100.332 17.1631ZM112.584 15.0453C112.584 12.5621 111.026 10.079 107.799 10.079C107.038 10.1882 105.261 11.0281 104.464 11.9056C103.92 12.8547 103.558 13.9506 103.376 15.0466H112.583L112.584 15.0453Z"
                  fill="#414756"
                />
                <path
                  d="M119.725 22.3477V11.3921L119.543 8.58007L120.123 7.95864H122.588L122.733 10.2961C124.473 8.94539 126.359 7.74023 127.664 7.74023C128.678 7.74023 130.056 8.76208 130.745 9.56681L130.637 10.2974L128.535 12.1968C128.172 11.4662 127.411 10.4808 126.794 10.261C125.997 10.3703 123.858 11.8679 122.77 12.7806V22.2749L122.915 25.1597L122.334 25.7811H119.543L119.725 22.349V22.3477Z"
                  fill="#414756"
                />
                <path
                  d="M16.2425 0.296875H11.8178L10.6934 1.46693L14.6042 11.961C14.8068 12.5057 15.5508 12.5642 15.8346 12.0572L17.1303 9.74179C18.0861 8.03612 18.2587 5.99633 17.6063 4.15024L16.2412 0.296875H16.2425Z"
                  fill="#414756"
                />
                <path
                  d="M29.5852 0.296875L28.6425 1.28362L22.9497 19.292L20.4367 12.1547C20.2406 11.5957 19.4849 11.5229 19.1855 12.0325L18.0205 14.0216C16.9876 15.7832 16.8046 17.9217 17.5211 19.8367L19.7229 25.7208H22.6229L23.7826 24.734L32.1596 0.296875H29.5852Z"
                  fill="#414756"
                />
                <path
                  d="M12.5819 19.6222L5.65616 0.296875H1.12443L0 1.46693L9.2099 25.7208H12.1099L13.2696 24.734L15.4414 19.2153C16.0519 17.6643 16.0729 15.9418 15.5002 14.3765L15.1197 13.3377L12.5819 19.6222Z"
                  fill="#414756"
                />
                <path
                  d="M21.5728 0.296875L20.63 1.28362L19.414 4.80547C18.9106 6.26154 18.9172 7.84761 19.431 9.30107L19.9423 10.7454L24.1839 0.296875H21.5728Z"
                  fill="#414756"
                />
              </svg>
            ),
            hoverImg: (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="150"
                height="30"
                viewBox="0 0 150 30"
                fill="none"
              >
                <path
                  d="M48.941 29.7463L48.8165 27.6387C47.1529 28.7337 44.9898 29.9983 43.9502 29.9983C39.375 29.9983 36.8804 27.5127 36.8804 24.226C36.8804 22.9614 37.5044 21.0233 38.2529 20.0963C39.834 18.8737 44.0762 17.1891 47.0704 17.1891C47.4859 17.1891 48.1099 17.2311 48.7339 17.3151V16.5981C48.7339 13.6069 47.2369 11.3733 44.2007 11.3733C42.8282 11.7528 40.9561 13.6489 40.2075 14.702L39.21 14.66L37.2974 12.3844C38.712 11.2053 42.2056 8.92969 44.4512 8.92969C49.775 8.92969 52.1451 12.5539 52.1451 16.2621V25.7006L52.3116 29.0292L51.6456 29.7463H48.9425H48.941ZM44.9073 27.1766C45.9048 26.7971 47.6524 25.8701 48.7339 25.027V19.8863C47.8189 19.7182 46.8619 19.6327 46.3218 19.6327C44.8248 19.6327 42.1216 20.5598 41.0821 21.2333C40.5826 21.9504 40.209 22.7079 40.209 23.8885C40.209 26.3741 41.9971 27.1751 44.9088 27.1751L44.9073 27.1766Z"
                  fill="black"
                />
                <path
                  d="M64.6661 27.3017C65.6231 26.7122 67.0362 25.2796 67.7847 24.3106L68.4912 24.2266L70.1968 25.8692C68.3247 27.9333 65.6216 29.9989 64.207 29.9989C60.9624 29.9989 59.2583 28.1448 59.2583 24.4366V11.6274L55.3911 11.7954L54.9756 11.0784L55.8081 8.97079L59.2598 9.1388V6.61117L59.0933 3.07099L59.6753 2.43945H62.9605L62.752 6.52716V9.1823L68.5332 8.97079L68.9488 9.68783L68.1162 11.7954L62.7505 11.5854V23.8891C62.7505 26.0807 63.166 27.3017 64.6631 27.3017H64.6661Z"
                  fill="black"
                />
                <path
                  d="M81.3841 11.6253C80.511 11.7513 78.3899 12.7204 77.4749 13.7329C76.4353 15.545 76.1443 17.7366 76.1443 19.6747C76.1443 24.184 78.972 27.3011 83.0477 27.3011C84.5867 27.0911 86.4993 25.489 87.4144 24.268L88.1209 24.184L89.8265 25.4485C88.2049 27.5126 85.0428 29.9998 82.1731 29.9998C76.0183 29.9998 72.6492 25.1965 72.6492 19.8022C72.6492 17.9062 73.2312 15.6725 74.1042 13.9024C75.9343 11.4168 79.3035 8.92969 82.1731 8.92969C85.3338 8.92969 87.7879 10.3623 89.327 12.5539L89.1185 13.4809L86.6223 15.0395C85.4988 13.1854 83.6282 11.6268 81.3811 11.6268L81.3841 11.6253Z"
                  fill="black"
                />
                <path
                  d="M107.006 25.7893V17.7834C107.006 14.5388 106.049 11.6316 102.597 11.6316C101.682 11.7576 99.0613 13.6957 97.7728 14.7503V25.7053L97.9393 29.034L97.2732 29.751H94.1531L94.3616 25.7908V3.9617L94.1531 0.717037L94.8191 0H97.9798L97.7713 4.04571V11.8416C99.7258 10.325 101.889 8.93446 103.386 8.93446C108.918 8.93446 110.415 13.3162 110.415 17.4459V25.7053L110.582 29.034L109.916 29.751H106.797L107.006 25.7908V25.7893Z"
                  fill="black"
                />
                <path
                  d="M115.107 19.8023C115.107 17.9062 115.689 15.6725 116.562 13.9024C118.392 11.4168 121.761 8.92969 124.631 8.92969C128.831 8.92969 132.409 11.9628 132.409 18.0727L130.205 19.9688H118.436C118.56 24.3085 121.514 27.3011 125.505 27.3011C127.169 27.0911 128.833 25.7846 129.748 24.7735L130.454 24.6895L132.16 25.8686C130.538 27.7227 127.627 29.9983 124.632 29.9983C118.478 29.9983 115.108 25.195 115.108 19.8008L115.107 19.8023ZM129.164 17.3586C129.164 14.4935 127.376 11.6283 123.674 11.6283C122.801 11.7543 120.762 12.7234 119.847 13.7359C119.223 14.831 118.808 16.0956 118.599 17.3601H129.163L129.164 17.3586Z"
                  fill="black"
                />
                <path
                  d="M137.36 25.7846V13.1434L137.151 9.89874L137.817 9.1817H140.645L140.812 11.8788C142.808 10.3203 144.971 8.92969 146.468 8.92969C147.632 8.92969 149.213 10.1087 150.004 11.0373L149.879 11.8803L147.467 14.072C147.052 13.2289 146.179 12.0919 145.471 11.8383C144.556 11.9643 142.102 13.6924 140.854 14.7455V25.7006L141.02 29.0292L140.354 29.7463H137.151L137.36 25.7861V25.7846Z"
                  fill="black"
                />
                <path
                  d="M33.9428 0.342773L32.8613 1.48133L26.33 22.2604L23.4468 14.025C23.2218 13.3799 22.3548 13.2959 22.0113 13.884L20.6747 16.1791C19.4896 18.2117 19.2796 20.6793 20.1017 22.8889L22.6278 29.6783H25.955L27.2855 28.5397L36.8965 0.342773H33.9428Z"
                  fill="black"
                />
                <path
                  d="M24.7492 0.342773L23.6676 1.48133L22.2725 5.54504C21.6949 7.22513 21.7024 9.05522 22.292 10.7323L22.8785 12.3989L27.7449 0.342773H24.7492Z"
                  fill="black"
                />
                <path
                  d="M18.6365 0.342773H13.5601L12.27 1.69284L16.7569 13.8015C16.9894 14.43 17.843 14.4975 18.1685 13.9125L19.6551 11.2408C20.7517 9.27273 20.9497 6.91911 20.2012 4.789L18.635 0.342773H18.6365Z"
                  fill="black"
                />
                <path
                  d="M14.4352 22.6414L6.48931 0.342773H1.29006L0 1.69284L10.5665 29.6783H13.8937L15.2242 28.5397L17.7159 22.1719C18.4164 20.3823 18.4404 18.3947 17.7834 16.5886L17.3468 15.3901L14.4352 22.6414Z"
                  fill="black"
                />
              </svg>
            ),
            hoverImgMob: (
              <svg
                width="131"
                height="26"
                viewBox="0 0 131 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M42.6606 25.7811L42.5521 23.9546C41.1021 24.9036 39.2167 25.9995 38.3106 25.9995C34.3228 25.9995 32.1484 23.8453 32.1484 20.9969C32.1484 19.901 32.6923 18.2213 33.3448 17.4179C34.7229 16.3583 38.4204 14.8984 41.0302 14.8984C41.3923 14.8984 41.9362 14.9348 42.4802 15.0076V14.3861C42.4802 11.7938 41.1753 9.85803 38.529 9.85803C37.3326 10.1869 35.7009 11.8302 35.0484 12.7429L34.179 12.7065L32.5119 10.7343C33.7449 9.71242 36.79 7.74023 38.7473 7.74023C43.3876 7.74023 45.4534 10.8812 45.4534 14.0949V22.2749L45.5985 25.1597L45.018 25.7811H42.6619H42.6606ZM39.1448 23.5541C40.0142 23.2252 41.5375 22.4218 42.4802 21.6912V17.2359C41.6826 17.0902 40.8484 17.0161 40.3777 17.0161C39.0729 17.0161 36.7168 17.8196 35.8107 18.4033C35.3753 19.0247 35.0497 19.6813 35.0497 20.7044C35.0497 22.8586 36.6083 23.5528 39.1461 23.5528L39.1448 23.5541Z"
                  fill="black"
                />
                <path
                  d="M56.3663 23.6585C57.2004 23.1475 58.4321 21.906 59.0845 21.0662L59.7003 20.9934L61.1869 22.4169C59.5552 24.2058 57.1991 25.996 55.9662 25.996C53.1381 25.996 51.6528 24.3891 51.6528 21.1754V10.0742L48.2821 10.2198L47.9199 9.59836L48.6456 7.77178L51.6541 7.91738V5.72679L51.509 2.65865L52.0163 2.11133H54.8796L54.6979 5.65398V7.95508L59.7369 7.77178L60.0991 8.3932L59.3735 10.2198L54.6966 10.0378V20.7008C54.6966 22.6002 55.0588 23.6585 56.3636 23.6585H56.3663Z"
                  fill="black"
                />
                <path
                  d="M70.9396 10.0764C70.1787 10.1856 68.3299 11.0255 67.5323 11.903C66.6263 13.4735 66.3726 15.3729 66.3726 17.0525C66.3726 20.9605 68.8372 23.662 72.3896 23.662C73.7311 23.48 75.3981 22.0916 76.1957 21.0333L76.8115 20.9605L78.2981 22.0565C76.8848 23.8453 74.1286 26.0008 71.6274 26.0008C66.2628 26.0008 63.3262 21.8381 63.3262 17.163C63.3262 15.5198 63.8335 13.584 64.5944 12.0499C66.1896 9.89573 69.1262 7.74023 71.6274 7.74023C74.3822 7.74023 76.5213 8.98179 77.8628 10.8812L77.681 11.6846L75.5054 13.0354C74.5261 11.4285 72.8956 10.0777 70.937 10.0777L70.9396 10.0764Z"
                  fill="black"
                />
                <path
                  d="M93.2709 22.3506V15.4122C93.2709 12.6002 92.4367 10.0806 89.4282 10.0806C88.6306 10.1898 86.3464 11.8695 85.2233 12.7835V22.2778L85.3684 25.1626L84.7879 25.784H82.0684L82.2501 22.3519V3.43345L82.0684 0.621427L82.6489 0H85.4037L85.222 3.50625V10.2627C86.9257 8.94829 88.811 7.74314 90.1159 7.74314C94.9379 7.74314 96.2428 11.5406 96.2428 15.1197V22.2778L96.3879 25.1626L95.8074 25.784H93.0891L93.2709 22.3519V22.3506Z"
                  fill="black"
                />
                <path
                  d="M100.332 17.1631C100.332 15.5198 100.839 13.584 101.6 12.0499C103.195 9.89573 106.132 7.74023 108.633 7.74023C112.294 7.74023 115.413 10.369 115.413 15.6641L113.492 17.3074H103.233C103.342 21.0684 105.916 23.662 109.395 23.662C110.845 23.48 112.295 22.3477 113.093 21.4714L113.709 21.3986L115.195 22.4205C113.782 24.0274 111.244 25.9995 108.635 25.9995C103.27 25.9995 100.333 21.8368 100.333 17.1618L100.332 17.1631ZM112.584 15.0453C112.584 12.5621 111.026 10.079 107.799 10.079C107.038 10.1882 105.261 11.0281 104.464 11.9056C103.92 12.8547 103.558 13.9506 103.376 15.0466H112.583L112.584 15.0453Z"
                  fill="black"
                />
                <path
                  d="M119.725 22.3477V11.3921L119.543 8.58007L120.123 7.95864H122.588L122.733 10.2961C124.473 8.94539 126.359 7.74023 127.664 7.74023C128.678 7.74023 130.056 8.76208 130.745 9.56681L130.637 10.2974L128.535 12.1968C128.172 11.4662 127.411 10.4808 126.794 10.261C125.997 10.3703 123.858 11.8679 122.77 12.7806V22.2749L122.915 25.1597L122.334 25.7811H119.543L119.725 22.349V22.3477Z"
                  fill="black"
                />
                <path
                  d="M16.2425 0.296875H11.8178L10.6934 1.46693L14.6042 11.961C14.8068 12.5057 15.5508 12.5642 15.8346 12.0572L17.1303 9.74179C18.0861 8.03612 18.2587 5.99633 17.6063 4.15024L16.2412 0.296875H16.2425Z"
                  fill="black"
                />
                <path
                  d="M29.5852 0.296875L28.6425 1.28362L22.9497 19.292L20.4367 12.1547C20.2406 11.5957 19.4849 11.5229 19.1855 12.0325L18.0205 14.0216C16.9876 15.7832 16.8046 17.9217 17.5211 19.8367L19.7229 25.7208H22.6229L23.7826 24.734L32.1596 0.296875H29.5852Z"
                  fill="black"
                />
                <path
                  d="M12.5819 19.6222L5.65616 0.296875H1.12443L0 1.46693L9.2099 25.7208H12.1099L13.2696 24.734L15.4414 19.2153C16.0519 17.6643 16.0729 15.9418 15.5002 14.3765L15.1197 13.3377L12.5819 19.6222Z"
                  fill="black"
                />
                <path
                  d="M21.5728 0.296875L20.63 1.28362L19.414 4.80547C18.9106 6.26154 18.9172 7.84761 19.431 9.30107L19.9423 10.7454L24.1839 0.296875H21.5728Z"
                  fill="black"
                />
              </svg>
            ),
          },
        ].map((item, index) => (
          <SwiperSlide
            key={index}
            onClick={() => handleSlideClick(index)}
            className={`swiper-item ${activeIndex === index ? "active" : ""}`}
          >
            <div className="default-img pc_only">{item.defaultImg}</div>
            <div className="default-img pc_none">{item.defaultImgMob}</div>
            <div className="hover-img pc_only">{item.hoverImg}</div>
            <div className="hover-img pc_none">{item.hoverImgMob}</div>
          </SwiperSlide>
        ))}
      </Swiper>
      <button className="prev-btn">
        <svg
          className="pc_only"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M24 22.6663L17.3333 15.9997L24 9.33301M14.6667 22.6663L8 15.9997L14.6667 9.33301"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          className="pc_none"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18 17L13 12L18 7M11 17L6 12L11 7"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      <button className="next-btn">
        <svg
          className="pc_only"
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M8 22.6663L14.6667 15.9997L8 9.33301M17.3333 22.6663L24 15.9997L17.3333 9.33301"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <svg
          className="pc_none"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 17L11 12L6 7M13 17L18 12L13 7"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </BrandSwiperWrap>
  );
};

export default BrandSwiper;
